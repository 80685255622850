import React, { Component } from "react";
import axios from "axios";
import {
  api_v2_warden_data_url,
  api_v2_warden_filter_url,
} from "../../../config";
import WardenList from "./WardenList";
import "./scss/style.scss";

class WardensOnDuty extends Component {
  state = {
    noEvent: false,
    loader: false,
    building_id_default: 0,
    buildings: [],
    roles: [],
  };

  loader = (loader) => {
    this.setState({
      loader,
    });
  };

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    this.loader(true);
    axios
      .get(api_v2_warden_data_url)
      .then((resp) => {
        if (resp.data.error !== undefined) {
          this.setState({
            noEvent: true,
          });
        } else {
          this.setState({
            buildings: resp.data.buildings,
            roles: resp.data.roles,
            building_id_default: resp.data.buildings[0].id,
          });
          this.getFilterData(resp.data.buildings[0].id);
        }
        this.loader(false);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  updateBuildingsTotal = (buildingsA, buildingsB) => {
    const updatedBuildings = buildingsA.map((buildingA) => {
      const matchingBuilding = buildingsB.find(
        (buildingB) => buildingB.id === buildingA.id
      );
      if (matchingBuilding) {
        return {
          ...buildingA,
          total: { ...matchingBuilding.total },
        };
      }
      return buildingA;
    });

    return updatedBuildings;
  };

  getFilterData = (e) => {
    this.loader(true);
    axios
      .get(api_v2_warden_filter_url + e)
      .then((resp) => {
        if (resp.data.error !== undefined) {
          this.setState({
            noEvent: true,
          });
        } else {
          this.setState({
            buildings: this.updateBuildingsTotal(
              this.state.buildings,
              resp.data.buildings
            ),
            building_id_default: e,
            roles: resp.data.roles,
          });
        }
        this.loader(false);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  render() {
    return (
      <React.Fragment>
        <WardenList
          loader={this.state.loader}
          dataBuildings={this.state.buildings}
          dataRoles={this.state.roles}
          building_id_default={this.state.building_id_default}
          handleFilterData={this.getFilterData}
        />
      </React.Fragment>
    );
  }
}

export default WardensOnDuty;
