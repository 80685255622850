import React, { Component } from "react";
import Loader from "../../Utilities/Loader";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";
import { Link } from "react-router-dom";
import { route } from "../../Routes";
import MessageBox, { TEMP } from "../Template/MessageBox";
import {
  api_message_evacueegroup_send,
  api_message_evacueegroup_data,
} from "../../../config";
import axios from "axios";
import { ArrowChevronIcon } from "../../Icons";

class EvacueeGroupMessage extends Component {
  state = {
    loading: false,
    sms_allowed: false,
    toast: {
      show: false,
      type: TOAST_TYPE.SUCCESS,
      message: "",
    },
    filters: {},
    count: 0,
  };

  componentDidMount() {
    const { filters, sms_allowed, searchText } = this.props.location.state;

    if (filters !== undefined && sms_allowed !== undefined) {
      this.setState(
        {
          filters: {
            ...filters,
            search: searchText,
          },
          sms_allowed,
        },
        () => {
          this.getInitialData();
        }
      );
    } else {
    }
  }

  closeToast = () => {
    this.setState({
      ...this.state,
      toast: {
        ...this.state.toast,
        show: false,
      },
    });
  };

  getInitialData = () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    axios
      .post(api_message_evacueegroup_data, { ...this.state.filters })
      .then((res) => {
        if (res.data.count !== undefined) {
          this.setState({
            ...this.state,
            loading: false,
            count: res.data.count,
          });
        }
      });
  };

  send = (message) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    let params = {
      ...this.state.filters,
      msg: message,
    };

    axios
      .post(api_message_evacueegroup_send, { ...params })
      .then((resp) => {
        if (resp.data.status === "message_sent") {
          this.setState({
            ...this.state,
            loading: false,
            toast: {
              type: TOAST_TYPE.SUCCESS,
              show: true,
              message: resp.data.message,
            },
          });
        } else {
          throw new Error(resp.data.status);
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
          toast: {
            type: TOAST_TYPE.DANGER,
            show: true,
            message: "Failed to send message",
          },
        });
      });
  };

  renderTopBar = () => {
    return (
      <div className="groupmsg__head">
        <div className="groupmsg__head-back">
          <Link
            to={route.evacuee.safety.link}
            className="groupmsg__head-back--btn"
          >
            <ArrowChevronIcon
              direction="left"
              width={32}
              height={32}
              fill="white"
            />
          </Link>
        </div>
        <div className="groupmsg__head-evacuee">
          <span className="info-text">SMS Count: </span>
          <span className="info-bubble color--blue">{this.state.count}</span>
        </div>
      </div>
    );
  };

  renderMessageBox = () => {
    return (
      <MessageBox
        loading={this.state.loading}
        send={this.send}
        canSend={this.state.sms_allowed}
        sms={this.state.sms_allowed}
        group={true}
        type={TEMP.EVACUEE}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <Toast
          show={this.state.toast.show}
          close={this.closeToast}
          toastType={this.state.toast.toastType}
          delay={2000}
        >
          {this.state.toast.message}
        </Toast>
        {this.renderTopBar()}
        <div className="bottom__marginfix">
          <div className="container row">
            <div className="col s12 mt-10 messengerlist__notfound">
              Message History Unavailable.
            </div>
          </div>
        </div>
        {this.renderMessageBox()}
      </React.Fragment>
    );
  }
}

export default EvacueeGroupMessage;
