import React from "react";
import _ from "lodash";
import CreateEventNotice from "../../../Utilities/CreateEventNotice/v2";
import Modal from "../../../Utilities/Modal";
import BroadcastMessageAndAlertToWardenButtons from "../BroadcastMessageAndAlertToWardenButtons";

function CreateEventModal(props) {
  const {
    isOpen,
    userInput,
    eventName,
    buildingName,
    eventModalAlert,
    renderSelectedBuildings,
    activeEvent,
    handleClose,
    handleSubmit,
  } = props;
  const isEventNotActive = _.get(activeEvent, "active", false) === false;

  return (
    <Modal
      id="confirmationPopup"
      show={isOpen}
      close={handleClose}
      baseClasses="modal-confirmation"
      contentClasses="modal-content-confirmation"
    >
      <div className="row mt-10">
        <div className="col s12">
          <CreateEventNotice
            message={_.get(eventModalAlert, "message")}
            variant={_.get(eventModalAlert, "variant")}
            fontWeight={_.get(eventModalAlert, "fontWeight", "unset")}
          />
        </div>
      </div>

      <div className="row ">
        <div className="col s12">
          <label>Building {buildingName}</label>
          {renderSelectedBuildings()}
        </div>
      </div>

      <div className="row">
        <div className="col s12">
          <label>Event</label>
          <input className="br-10" type="text" value={eventName} readOnly />
        </div>
      </div>

      <div className="row">
        <div className="col s12">
          <label>Default Message</label>
          <textarea value={userInput.message} readOnly />
        </div>
      </div>

      <div className="row">
        <div className="col s12">
          <BroadcastMessageAndAlertToWardenButtons
            broadcastMessage={userInput.broadcastMessage}
            alertToWarden={userInput.alertToWarden}
            isDisableBroadcastMessageButton
            showAlertToWardenToggle={isEventNotActive}
          />
        </div>
      </div>

      <div className="row actionGroup">
        <div className="col s6 center-align">
          <button className="secondary-btn" onClick={() => handleClose(false)}>
            Cancel
          </button>
        </div>

        <div className="col s6 center-align">
          <button className="primary-btn" onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CreateEventModal;
