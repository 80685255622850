import React, { Component } from "react";

import ReportForm from "../../ReportIn/ReportForm";

import { api_reportin_index_url } from "../../../config";

import axios from "axios";

class Role extends Component {
  state = {
    loading: false,
    current_events: "",
    role: {},
    locations: [],
    area: "",
    active_event: "",
  };

  componentDidMount() {
    this.loadReportData();
  }

  loading = (loading) => {
    this.setState({
      ...this.state,
      loading,
    });
  };

  /**
   * Loads ReportIn data from the server
   * and logs warden sign in data
   */
  loadReportData = () => {
    this.loading(true);

    axios
      .post(api_reportin_index_url, null)
      .then((res) => {
        this.setState({
          ...this.state,
          current_events: res.data.data.event,
          locations: res.data.data.locations,
          role: res.data.data.role,
          area: res.data.data.area,
          active_event: res.data.data.active_event,
        });

        // Update role
        this.props.updateRole(res.data.data.role);
      })
      .finally(() => this.loading(false));
  };

  render() {
    return (
      <React.Fragment>
        <ReportForm
          active_event={this.state.active_event}
          current_events={this.state.current_events}
          locations={this.state.locations}
          role={this.state.role}
          area={this.state.area}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

export default Role;
