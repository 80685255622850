import React, { Component } from "react";
import AllWardenLogo from "../../../images/allwardens-logo.svg";
import "./styles.scss";

class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="layout">
        <main className="layout__container">
          <div className="layout__header">
            <img src={AllWardenLogo} alt="logo" />

            <div className="layout__header-text">
              Emergency Event Managements
            </div>
          </div>

          {children}
        </main>
      </div>
    );
  }
}

export default Layout;
