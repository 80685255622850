import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { route } from "../Routes";
import ls from "local-storage";
import "./scss/sidenav.scss";

import {
  CloseIcon,
  // EvacueeSafetyIcon,
  // EventManagementIcon,
  // FloorEvacuationIcon,
  // FloorEvacStatusIcon,
  // GoOffDutyIcon,
  // SafetyHelmetIcon,
  // WardenOnDutyIcon,
  // WardenMesagingIcon,
} from "../Icons";

import EvacueeSafetyPng from "../../images/menus/EvacueeSafety.png";
import EventManagementPng from "../../images/menus/EventManagement.png";
import FloorEvacuationPng from "../../images/menus/FloorEvacuation.png";
import FloorEvacuationStatusPng from "../../images/menus/FloorEvacuationStatus.png";
import MyRolePng from "../../images/menus/MyRole.png";
import SignOutPng from "../../images/menus/SignOut.png";
import WardenMessagingPng from "../../images/menus/WardenMessaging.png";
import WardensOnDutyPng from "../../images/menus/WardensOnDuty.png";
class SideNavigation extends Component {
  sideNavElem = null;
  sideNav = null;

  state = {
    loaded: false,
    access: null,
  };

  componentDidMount() {
    this.sideNavElem = this.refs.SideNavComponent;
    this.sideNav = window.M.Sidenav.init(this.sideNavElem, {
      draggable: this.props.draggable,
      onCloseStart: () => {
        this.props.close();
      },
    });
    this.loadSideNav();

    this.getModuleAccess();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.access === null) {
      this.getModuleAccess();
    }
    this.loadSideNav();
  }

  componentWillUnmount() {
    this.sideNav.destroy();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.updateNav !== nextProps.updateNav) {
      this.getModuleAccess();
      return nextState;
    }
    return nextState;
  }

  getModuleAccess = () => {
    if (ls.get("module_access") !== null) {
      this.setState({
        loaded: true,
        access: ls.get("module_access"),
      });
    }
  };

  loadSideNav = () => {
    // var sideNavElem = this.refs.SideNavComponent;
    // var sideNav = window.M.Sidenav.init(sideNavElem, {
    //   draggable: this.props.draggable,
    //   onCloseStart: () => {
    //     this.props.close();
    //   }
    // });

    // if (this.props.show) {
    //   sideNav.open();
    // } else {
    //   sideNav.close();
    // }

    if (this.props.show) {
      this.sideNav.open();
    } else {
      this.sideNav.close();
    }
  };

  handleSignOut = () => {
    this.props.toggleNav(this.props.openModal);
  };

  renderActionButton = () => {
    if (this.props.title === route.reportinlist.title) {
      if (this.props.showBurgerMenu) {
        return (
          <button
            data-target="slide-out"
            className="sidenav-trigger left"
            onClick={this.props.toggleNav}
          >
            <i className="material-icons">menu</i>
          </button>
        );
      }
    } else {
      if (this.props.showBurgerMenu) {
        return (
          <button
            data-target="slide-out"
            className="sidenav-trigger left"
            onClick={this.props.toggleNav}
          >
            <i className="material-icons">menu</i>
          </button>
        );
      } else if (this.props.showBackBtn && !this.props.showBurgerMenu) {
        return (
          <button
            className="sidenav-trigger left"
            onClick={this.props.history.goBack}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </button>
        );
      } else {
        return null;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <ul id="slide-out" className="sidenav" ref="SideNavComponent">
          <li className="sidenav__title">
            <span>Menu</span>
            <button
              data-target="slide-out"
              className="sidenav__close"
              onClick={this.props.toggleNav}
            >
              <CloseIcon width={22} height={22} />
            </button>
          </li>

          <li className="sidenav__subtitle">evacuee management</li>
          {this.state.loaded && this.state.access.Module_Floor_Evacuation ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.floor.evacuation.link}>
                {/* <span className="span--icon icon--floor-evac">
                  <FloorEvacuationIcon width={33} height={33} />
                </span> */}
                <span className="span--png">
                  <img src={FloorEvacuationPng} alt="icon" />
                </span>
                <span className="span--text">floor evacuation</span>
              </Link>
            </li>
          ) : null}

          {this.state.loaded && this.state.access.Module_Evacuee_Safety ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.evacuee.safety.link}>
                {/* <span className="span--icon">
                  <EvacueeSafetyIcon width={26} height={26} />
                </span> */}
                <span className="span--png">
                  <img src={EvacueeSafetyPng} alt="icon" />
                </span>
                <span className="span--text">evacuee safety</span>
              </Link>
            </li>
          ) : null}

          <li className="sidenav__subtitle">evacuation status</li>
          {this.state.loaded &&
          this.state.access.Module_Floor_Evacuation_Status ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.floor.evacstatus.link}>
                {/* <span className="span--icon">
                  <FloorEvacStatusIcon width={31} height={31} />
                </span> */}
                <span className="span--png">
                  <img src={FloorEvacuationStatusPng} alt="icon" />
                </span>
                <span className="span--text">floor evacuation status</span>
              </Link>
            </li>
          ) : null}

          {/*
          {this.state.loaded &&
          this.state.access.Module_Building_Evacuation_Status ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.building.status.link}>
                building evacuation status
              </Link>
            </li>
          ) : null}
          */}

          {this.state.loaded && this.state.access.Module_Wardens_On_Duty ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.warden.onduty.link}>
                {/* <span className="span--icon">
                  <WardenOnDutyIcon width={33} height={33} />
                </span> */}
                <span className="span--png">
                  <img src={WardensOnDutyPng} alt="icon" />
                </span>
                <span className="span--text">{route.warden.onduty.title}</span>
              </Link>
            </li>
          ) : null}

          {/*
          {this.state.loaded && this.state.access.Module_Floor_Warden_Status ? (
            <li className="sidenav__link">
              <Link
                onClick={this.props.close}
                to={route.floor.warden.status.link}
              >
                <FloorWardenIcon width={20} height={20} />
                {route.floor.warden.status.title}
              </Link>
            </li>
          ) : null}
          */}

          <li className="sidenav__subtitle">warden activities</li>
          {/* <li className="sidenav__link">
            <a href="/cw/broadcast">
              warden messaging
            </a>
          </li> */}
          {this.state.loaded && this.state.access.Module_Warden_Messaging ? (
            <li className="sidenav__link">
              <Link to={route.message.all.link} onClick={this.props.close}>
                {/* <span className="span--icon">
                  <WardenMesagingIcon width={28} height={28} />
                </span> */}
                <span className="span--png">
                  <img src={WardenMessagingPng} alt="icon" />
                </span>
                <span className="span--text">{route.message.all.title}</span>
              </Link>
            </li>
          ) : null}
          {this.state.loaded && this.state.access.Module_Event_Management ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.event.management.link}>
                {/* <span className="span--icon">
                  <EventManagementIcon width={28} height={28} />
                </span> */}
                <span className="span--png">
                  <img src={EventManagementPng} alt="icon" />
                </span>
                <span className="span--text">
                  {route.event.management.title}
                </span>
              </Link>
            </li>
          ) : null}

          <li className="sidenav__link">
            <Link onClick={this.props.close} to={route.me.role.link}>
              {/* <span className="span--icon">
                <SafetyHelmetIcon width={29} height={29} />
              </span> */}
              <span className="span--png">
                <img src={MyRolePng} alt="icon" />
              </span>
              <span className="span--text">{route.me.role.title}</span>
            </Link>
          </li>

          <li className="sidenav__link">
            <button onClick={this.handleSignOut}>
              {/* <span className="span--icon">
                <GoOffDutyIcon width={30} height={30} />
              </span> */}
              <span className="span--png">
                <img src={SignOutPng} alt="icon" />
              </span>
              <span className="span--text">Go Off Duty</span>
            </button>
          </li>
        </ul>
        {this.renderActionButton()}
      </React.Fragment>
    );
  }
}

export default withRouter(SideNavigation);
