import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { AlertModal } from "../../../UI/Modal/AlertModal";
import { ArrowChevronIcon, PhoneIcon } from "../../../Icons";
import PhoneLinkIcon from "../../../../images/icon-phonelink.png";
import { api_v2_warden_send_report_in_links } from "../../../../config";
import "./scss/recipientinfo.scss";

export const INFO_TYPE = {
  WARDEN: "warden",
  EVACUEE: "evacuee",
};

class RecipientInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertModal: {
        isOpen: false,
        message: "",
        variant: "", // success | error | warn
      },
    };
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });
  };
  render() {
    const isRecipientStatusIsNotUnknown =
      _.get(this.props.recipient, "status", "").toLowerCase() !== "unknown";

    const renderRecipientStatus = () => {
      let status = this.props.recipient.status.toUpperCase();
      let className = "recipient__status--badge green--bg";

      if (status === "UNKNOWN") {
        className = "recipient__status--badge yellow--bg";
      } else if (
        status === "IN DANGER" ||
        status === "OFF DUTY" ||
        status === "NOT AVAILABLE"
      ) {
        className = "recipient__status--badge red--bg";
      }

      return (
        <span
          className={className}
          data-status={this.props.recipient.status.toUpperCase()}
        >
          {this.props.recipient.status}
        </span>
      );
    };

    const handleSendReportInLink = async () => {
      await axios
        .post(api_v2_warden_send_report_in_links, {
          warden_sign_in_uids: [this.props.recipient.wardenSignInUid],
          sender_warden_sign_in_uid: this.props.senderWardenSignInUid,
        })
        .then(() =>
          this.handleOpenAlertModal("Report-in link has been sent.", "success")
        );
    };

    const renderInfo = () => {
      if (!this.props.loading) {
        return (
          <div className="recipient__info">
            <div className="recipient__back">
              <Link to={this.props.back} className="recipient__back--btn">
                <ArrowChevronIcon
                  direction="left"
                  width={32}
                  height={32}
                  fill="white"
                />
              </Link>
            </div>
            <div className="identity">
              <div className="identity__left">
                <div className="recipient__status">
                  {renderRecipientStatus()}
                </div>
              </div>
              <div
                className={`identity__middle ${
                  this.props.type === INFO_TYPE.WARDEN
                    ? "middle--warden"
                    : "middle--evacuee"
                }`}
              >
                <div className="recipient__name">
                  {this.props.recipient.name}
                </div>
                {this.props.type === INFO_TYPE.WARDEN ? (
                  <div className="recipient__role" data-type={this.props.type}>
                    {this.props.recipient.role}
                  </div>
                ) : null}
                <div className="recipient__location">
                  {this.props.recipient.location}
                </div>
              </div>

              <div
                className={
                  this.props.type === INFO_TYPE.WARDEN
                    ? "identity__right--warden"
                    : "identity__right--evacuee"
                }
              >
                {this.props.type === INFO_TYPE.WARDEN ? (
                  <button
                    className="box-phonelink"
                    style={{
                      backgroundColor: isRecipientStatusIsNotUnknown
                        ? "#d8d8de"
                        : "",
                    }}
                    onClick={handleSendReportInLink}
                    disabled={isRecipientStatusIsNotUnknown}
                  >
                    <img src={PhoneLinkIcon} alt="phone link icon" />
                  </button>
                ) : null}
                <div className="box-contact">
                  <a href={`tel:${this.props.recipient.phone}`}>
                    <PhoneIcon width="20px" height="30px" fill="white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="col s12 recipient__info">
            <div className="col s12 skeleton mt-10">
              <div className="col s12 skeleton skeleton__field" />
              <div className="col s12 skeleton skeleton__field" />
            </div>
          </div>
        );
      }
    };

    return (
      <div className="row message__recipient">
        {/* <div className="col s2 recipient__image"></div> */}
        {renderInfo()}

        <AlertModal
          isOpen={this.state.alertModal.isOpen}
          message={this.state.alertModal.message}
          variant={this.state.alertModal.variant}
          onClose={this.handleCloseAlertModal}
        />
      </div>
    );
  }
}

export default RecipientInfo;
