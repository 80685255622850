import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { route } from "../../../../Routes";
import { MessageIcon, PhoneIcon } from "../../../../Icons";
import PeepPopUp from "../../../../Floor/Evacuation/PeepList/PeepPopUp";

class EvacueeListItem extends Component {
  state = {
    showStatusModal: false,
    peepPopUp: {
      isShow: false,
      details: {
        name: "",
        disability: "",
        assistance: "",
        other: "",
      },
    },
  };

  toggleStatusModal = () => {
    this.setState({
      ...this.state,
      showStatusModal: !this.state.showStatusModal,
    });
  };

  openPeepPopUp = (details) => {
    this.setState({
      peepPopUp: {
        isShow: true,
        details: {
          name: _.get(details, "name", ""),
          disability: _.get(details, "disability", ""),
          assistance: _.get(details, "assistance", ""),
          other: _.get(details, "other", ""),
        },
      },
    });
  };

  closePeepPopUp = () => {
    this.setState({
      peepPopUp: {
        isShow: false,
        details: {
          name: "",
          disability: "",
          assistance: "",
          other: "",
        },
      },
    });
  };

  setStatusDanger = () => {
    this.setState({
      showStatusModal: !this.state.showStatusModal,
    });
    this.props.setDanger(this.props.evacuee.id);
  };

  setStatusSafe = () => {
    this.setState({
      showStatusModal: !this.state.showStatusModal,
    });
    this.props.setSafe(this.props.evacuee.id);
  };

  setStatusUnknown = () => {
    this.setState({
      showStatusModal: !this.state.showStatusModal,
    });
    this.props.setUnknown(this.props.evacuee.id);
  };

  statusVariants = () => {
    let statusText = "";
    let statusTextOn = "";
    let assist = this.props.evacuee.special[0];
    let refusal = this.props.evacuee.special[2];
    let status = this.props.evacuee.status;

    switch (status) {
      case "safe":
        statusText = "Safe";
        statusTextOn = "Safe on";
        break;
      case "danger":
        statusText = "Danger";
        statusTextOn = "Danger on";
        break;
      default:
        statusText = "Unknown";
        statusTextOn = "Unknown on";
        break;
    }

    if (assist.name === "assist" && assist.state && assist.detail) {
      return (
        <>
          <span className="status-text">{statusTextOn}</span>
          <span className="status-location">
            {assist.detail ? assist.detail : null}
          </span>
        </>
      );
    } else if (refusal.name === "refusal" && refusal.state && refusal.detail) {
      return (
        <>
          <span className="status-text">{statusTextOn}</span>
          <span className="status-location">
            {refusal.detail ? refusal.detail : null}
          </span>
        </>
      );
    } else {
      return <span className="status-text">{statusText}</span>;
    }
  };

  additionalInfo = () => {
    return this.props.evacuee.special.map((special, idx) => {
      //IF Assist
      if (special.name === "assist" && special.state) {
        const name = this.props.evacuee.name;
        const disability = _.get(special, "peep.disability");
        const assistance = _.get(special, "peep.assistance");
        const other = _.get(special, "peep.other");

        return (
          <button
            key={idx}
            className="btn-peep"
            onClick={() =>
              this.openPeepPopUp({ disability, assistance, other, name })
            }
          >
            Peep
          </button>
        );
      }

      //IF Refusal
      if (special.name === "refusal" && special.state) {
        return (
          <span
            key={idx}
            className="tooltipped special__icon special__icon--refusal"
            title="refusal"
          />
        );
      }

      //DEFAULT (IF NOT ALL OF THEM)
      return null;
    });
  };

  messageVariants = () => {
    if (this.props.evacuee.message !== null) {
      let baseClass = "special__icon special__icon--";
      let noReplyClass = null;
      let unreadClass = null;
      if (this.props.evacuee.message.noreply) {
        noReplyClass = baseClass + "noreply";
      }
      if (this.props.evacuee.message.unread) {
        unreadClass = baseClass + "unread";
      }
      return (
        <React.Fragment>
          {unreadClass !== null ? <span className={unreadClass} /> : null}
          {noReplyClass !== null ? <span className={noReplyClass} /> : null}
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    let dangerDisabled = this.props.evacuee.status === "danger" ? true : false;
    let safeDisabled = this.props.evacuee.status === "safe" ? true : false;
    let unknownDisabled =
      this.props.evacuee.status === "unknown" ? true : false;

    return (
      <React.Fragment>
        <div className="card__evacuee">
          <div className="card__evacuee-left">
            <div className="card__evacuee-name">{this.props.evacuee.name}</div>
            <div className="card__evacuee-current-location">
              {this.props.evacuee.location}
            </div>
            <div className="card__evacuee-status">
              <button
                className={`status status--${this.props.evacuee.status}`}
                onClick={() => {
                  this.toggleStatusModal();
                }}
              >
                {this.statusVariants()}
              </button>

              {this.state.showStatusModal && (
                <>
                  <div className="status-modal">
                    {!safeDisabled ? (
                      <button
                        className="status status--safe"
                        onClick={this.setStatusSafe}
                      >
                        <span className="status-text uppercase">Safe</span>
                      </button>
                    ) : null}
                    {!unknownDisabled ? (
                      <button
                        className="status status--unknown"
                        onClick={this.setStatusUnknown}
                      >
                        <span className="status-text uppercase">Unknown</span>
                      </button>
                    ) : null}
                    {!dangerDisabled ? (
                      <button
                        className="status status--danger"
                        onClick={this.setStatusDanger}
                      >
                        <span className="status-text uppercase">Danger</span>
                      </button>
                    ) : null}
                  </div>

                  <div
                    className="status-modal-overlay"
                    onClick={() => {
                      this.toggleStatusModal();
                    }}
                  />
                </>
              )}

              {this.additionalInfo()}
            </div>
          </div>

          <div className="card__evacuee-right">
            <div className="indicators">
              <div className="message-icon">{this.messageVariants()}</div>
            </div>

            <div className="cta">
              <div className="btn-message">
                <Link
                  to={
                    route.message.evacuee.link +
                    "/" +
                    this.props.evacuee.id +
                    "?from=" +
                    route.evacuee.safety.link
                  }
                >
                  <MessageIcon width="25px" height="25px" fill="white" />
                </Link>
              </div>
              <div className="btn-phone">
                <a href={`tel:${this.props.evacuee.phone}`}>
                  <PhoneIcon width="25px" height="25px" fill="white" />
                </a>
              </div>
            </div>
          </div>

          <PeepPopUp
            show={this.state.peepPopUp.isShow}
            name={this.state.peepPopUp.details.name}
            details={this.state.peepPopUp.details}
            close={this.closePeepPopUp}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default EvacueeListItem;
