import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ls from "local-storage";
import axios from "axios";
import _ from "lodash";
import LogInWithMobileForm from "./LogInWithMobileForm";
import LogInWithEmailOrUsernameForm from "./LogInWithEmailOrUsernameForm";
import { AlertModal } from "../../UI/Modal/AlertModal";
import AllWardenLogo from "../../../images/allwardens-logo.svg";
import { api_verify_token, api_verify_reportin_token } from "../../../config";
import "./styles.scss";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertModal: {
        isOpen: false,
        message: "",
        variant: "", // success | error | warn
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const reportInToken = queryParams.get("reportInToken");

    if (Boolean(token)) {
      ls.set("isReportInToken", false);
      ls.set("signInMethod", "Mobile");

      this.verifyToken(api_verify_token, token);
    } else if (Boolean(reportInToken)) {
      ls.set("isReportInToken", true);
      this.verifyToken(api_verify_reportin_token, reportInToken);
    }
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });
  };

  verifyToken = async (url, token) => {
    const { history } = this.props;
    const res = await axios.post(url, { token });
    /*
      tokenStatus: Expired | Invalid-concluded | Invalid-Input | Invalid-NotFound | Invalid-Expired
    */

    if (_.get(res, "data.tokenStatus", "").toLowerCase() === "expired") {
      this.handleOpenAlertModal(
        "This sign-in link has expired. Please request another.",
        "error"
      );
    } else if (
      _.get(res, "data.tokenStatus", "").toLowerCase() === "invalid-concluded"
    ) {
      this.handleOpenAlertModal("This event has concluded.", "error");
    } else if (
      _.get(res, "data.tokenStatus", "").toLowerCase() === "invalid-notfound" &&
      _.get(res, "data.tokenStatus", "").toLowerCase() === "invalid-expired"
    ) {
      this.handleOpenAlertModal("This report-in link is invalid.", "error");
    } else {
      // Save auth to local storage
      ls.set("auth", {
        ...res.data,
        access_token: `Bearer ${res.data.access_token}`,
      });

      history.push("/reportinlist");
    }
  };

  render() {
    const { updateMe } = this.props;
    const { alertModal } = this.state;

    return (
      <>
        <div className="login-page">
          <main className="login-page__container">
            <div className="header">
              <img src={AllWardenLogo} alt="logo" />

              <div className="header--text">Emergency Event Management</div>
            </div>

            <LogInWithMobileForm />

            <div className="or">or</div>

            <LogInWithEmailOrUsernameForm updateMe={updateMe} />
          </main>

          <div className="login-page-footer">
            For authorised users of All Wardens.
          </div>

          <AlertModal
            isOpen={alertModal.isOpen}
            message={alertModal.message}
            variant={alertModal.variant}
            onClose={this.handleCloseAlertModal}
          />
        </div>
      </>
    );
  }
}

export default withRouter(Login);
