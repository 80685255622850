import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  AreasIcon,
  ArrowChevronIcon,
  BroadcastIcon,
  CloseIcon,
  NoReplyIcon,
  PeepIcon,
  ShowAllIcon,
  UnReadIcon,
  VisitorIcon,
} from "../../../Icons";
import { route } from "../../../Routes";
import { AREA, FILTER, MESSAGE } from "./index";

const AR = "area";
const MSG = "message";

class FilterControl extends Component {
  state = {
    area: AREA.MYAREA,
    showAreaOptions: false,
    toggleFloorbyId: 0,
    directSelectFloor: false,
  };

  toggleAreaOptions = () => {
    this.setState({
      ...this.state,
      showAreaOptions: !this.state.showAreaOptions,
    });
  };

  setArea = (area) => {
    this.setState(
      {
        ...this.state,
        area,
      },
      () => {
        this.props.setFilter(AR, this.state.area);
      }
    );
  };

  /**
   * Handles changes in the selected area (e.g., "my area", "everyone", or a specific building ID).
   * Updates the state and applies filters accordingly.
   *
   * @param {Object} event - The event object triggered by selecting an area.
   */
  handleAreaChange = (event) => {
    const selectedArea = event.target.value; // 'my area' or 'building id'

    if (selectedArea === AREA.MYAREA) {
      // If "My Area" is selected, update state and apply filter to show all floors.
      this.setState({ area: AREA.MYAREA, directSelectFloor: false });
      this.props.setFilterAreaAndFloor("area", AREA.MYAREA, "floors", ["all"]);
    } else if (selectedArea === AREA.EVERYONE) {
      // If "Everyone" is selected, update state and reset floor filters.
      this.setState({ area: AREA.EVERYONE, directSelectFloor: false });
      this.props.setFilter("floors", []);
    } else if (selectedArea !== AREA.MYAREA || selectedArea !== AREA.EVERYONE) {
      // Handle selection of a specific building.
      const currentArea = [AREA.MYAREA, AREA.EVERYONE].includes(this.state.area)
        ? []
        : this.state.area;
      let newArea = [];

      if (currentArea.includes(selectedArea)) {
        // If the selected building is already in the area filter, remove it.
        newArea = currentArea.filter((area) => area !== selectedArea);
      } else {
        // Otherwise, add the building to the area filter.
        newArea = [...currentArea, selectedArea];
      }

      if (newArea.length > 0) {
        // If there are selected buildings, find associated floor IDs.
        const floorIds = _.get(this.props, "floors", [])
          .filter((buildingData) =>
            newArea.includes(String(buildingData.building.id))
          )
          .flatMap((buildingData) =>
            buildingData.floors.map((floor) => String(floor.id))
          );

        // Update state and apply filters.
        this.setState({ area: newArea, directSelectFloor: false });
        this.props.setFilterAreaAndFloor("area", newArea, "floors", floorIds);
      } else {
        // If no buildings are selected, revert to "My Area" and show all floors.
        this.setState({ area: AREA.MYAREA, directSelectFloor: false });
        this.props.setFilterAreaAndFloor("area", AREA.MYAREA, "floors", [
          "all",
        ]);
      }
    }
  };

  /**
   * Handles floor selection changes.
   * Updates the selected area and applies corresponding filters.
   *
   * @param {string|number} floorId - The ID of the selected floor.
   */
  handleFloorChange = (floorId) => {
    // Find the building that contains the selected floor.
    const building = _.get(this.props, "floors", []).find((building) =>
      building.floors.some((floor) => String(floor.id) === String(floorId))
    );
    const buildingId = building ? building.building.id : null;
    let newBuilding = [String(buildingId)];

    // Handle direct select floor
    if (this.state.directSelectFloor) {
      // Check if the selected area has changed
      if (JSON.stringify(this.state.area) !== JSON.stringify(newBuilding)) {
        // Update state and apply filters.
        this.setState({ area: newBuilding });
        this.props.setFilterAreaAndFloor("area", newBuilding, "floors", []);
        // Notify parent component about floor selection change.
        this.props.handleFloorSelectionChange(String(floorId));
      } else {
        // Retain existing floors and apply the selected area.
        this.props.setFilterAreaAndFloor("area", newBuilding, "floors", [
          ...this.props.show.floors,
        ]);
        // Notify parent component about floor selection change.
        this.props.handleFloorSelectionChange(String(floorId));

        // If no floors are currently selected, update with all floors in the building.
        setTimeout(() => {
          console.log("After updating:", this.props.show); // Debugging log
          if (this.props.show.floors.length === 0) {
            // Retrieve all floor IDs for the selected building.
            this.selectAllFloorsByBuilding(buildingId, newBuilding);
          }
        }, 0);
      }
      // Handle direct select floor
    } else if (
      this.props.show.floors.length === 0 ||
      JSON.stringify(this.props.show.floors) === JSON.stringify(["all"]) ||
      JSON.stringify(this.state.area) !== JSON.stringify(newBuilding)
    ) {
      // If no floors are selected or the default area is set, update accordingly.
      this.setState({ area: newBuilding, directSelectFloor: true });
      this.props.setFilterAreaAndFloor("area", newBuilding, "floors", [
        String(floorId),
      ]);
      // Handle select floor via building
    } else {
      // If the area selection contains multiple buildings, get all floor IDs.
      if (this.state.area.length > 1) {
        const floorIds = _.get(this.props, "floors", [])
          .filter((buildingData) =>
            newBuilding.includes(String(buildingData.building.id))
          )
          .flatMap((buildingData) =>
            buildingData.floors.map((floor) => String(floor.id))
          );

        // Update state and apply filters.
        this.setState({ area: newBuilding });
        this.props.setFilterAreaAndFloor(
          "area",
          newBuilding,
          "floors",
          floorIds
        );
      }

      // Notify parent component about floor selection change.
      this.props.handleFloorSelectionChange(String(floorId));

      // If no floors are currently selected, update with all floors in the building.
      setTimeout(() => {
        console.log("After updating:", this.props.show); // Debugging log
        if (this.props.show.floors.length === 0) {
          // Retrieve all floor IDs for the selected building.
          this.selectAllFloorsByBuilding(buildingId, newBuilding);
        }
      }, 0);
    }
  };

  selectAllFloorsByBuilding = (buildingId, newBuilding) => {
    const floorIds = _.get(this.props, "floors", [])
      .filter(
        (buildingData) =>
          String(buildingData.building.id) === String(buildingId)
      )
      .flatMap((buildingData) =>
        buildingData.floors.map((floor) => String(floor.id))
      );

    // Update state and apply filters.
    this.setState({ area: newBuilding });
    this.props.setFilterAreaAndFloor("area", newBuilding, "floors", floorIds);
  };

  renderAreaBtn = () => {
    let onActive = this.state.showAreaOptions ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.toggleAreaOptions();
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          <AreasIcon width="35" height="28" />
        </div>
        <div className="evacsafety__menu-btn-label">Areas</div>
      </div>
    );
  };

  renderShowAllBtn = () => {
    let name = this.props.show.message;
    let icon = null;
    let changeTo = null;

    if (name === MESSAGE.SHOWALL) {
      icon = <ShowAllIcon width="25" height="30" />;
      changeTo = MESSAGE.UNREAD;
    } else if (name === MESSAGE.UNREAD) {
      icon = <UnReadIcon width="25" height="30" />;
      changeTo = MESSAGE.NOREPLY;
    } else if (name === MESSAGE.NOREPLY) {
      icon = <NoReplyIcon width="25" height="30" />;
      changeTo = MESSAGE.SHOWALL;
    }

    let onActive = this.props.show.message ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(MSG, changeTo);
        }}
      >
        <div className="evacsafety__menu-btn-icon">{icon}</div>
        <div className="evacsafety__menu-btn-label">{name}</div>
        <div className="evacsafety__menu-btn-boba">
          <span
            className={`boba ${
              changeTo === MESSAGE.UNREAD ? "on--active" : ""
            }`}
          />
          <span
            className={`boba ${
              changeTo === MESSAGE.NOREPLY ? "on--active" : ""
            }`}
          />
          <span
            className={`boba ${
              changeTo === MESSAGE.SHOWALL ? "on--active" : ""
            }`}
          />
        </div>
      </div>
    );
  };

  renderAssistBtn = () => {
    let onActive = this.props.show.assist ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(FILTER.ASSIST, !this.props.show.assist);
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          <PeepIcon width="25" height="30" />
        </div>
        <div className="evacsafety__menu-btn-label">Assist</div>
      </div>
    );
  };

  renderVisitorBtn = () => {
    let onActive = this.props.show.visitor ? "on--active" : "";
    let onActiveIcon = this.props.show.visitor ? (
      <VisitorIcon width="38" height="28" fill="black" />
    ) : (
      <VisitorIcon width="38" height="28" fill="white" />
    );

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(FILTER.VISITOR, !this.props.show.visitor);
        }}
      >
        <div className="evacsafety__menu-btn-icon">{onActiveIcon}</div>
        <div className="evacsafety__menu-btn-label">Visitor</div>
      </div>
    );
  };

  canMessageAll = (event) => {
    if (this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      event.preventDefault();
    }
  };

  renderMessageAllBtn = () => {
    let className = "evacsafety__menu-btn";

    if (this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      className = className + " " + className + "--disabled";
    }

    return (
      <div className={className}>
        <Link
          to={{
            pathname: route.message.evacueegroup.link,
            state: {
              filters: this.props.filters,
              searchText: this.props.searchText,
              sms_allowed: this.props.sms_allowed,
            },
          }}
          onClick={this.canMessageAll}
          className="text-white"
        >
          <div className="evacsafety__menu-btn-icon">
            <BroadcastIcon width="30" height="30" />
          </div>
          <div className="evacsafety__menu-btn-label">Broadcast</div>
        </Link>
      </div>
    );
  };

  toggleFloor = (e) => {
    this.setState({
      toggleFloorbyId: this.state.toggleFloorbyId === e ? 0 : e,
    });
  };

  renderFloor = () => {
    return this.props.floors.map((item) => {
      return item.floors.map((floor) => {
        return (
          <li
            className="evacuee__areaoptions--listitem listitem-floor"
            key={"floorSearch--" + item.building.id + floor.id}
          >
            <label className="item-label">
              <input
                type="checkbox"
                className="filled-in"
                value={floor.id}
                onChange={(e) =>
                  this.props.handleFloorSelectionChange(floor.id.toString())
                }
                checked={this.props.isFloorSelected(floor.id.toString())}
              />
              <span className="item-overlay" />
              <span className="item-text">{floor.name}</span>
            </label>
          </li>
        );
      });
    });
  };

  getFloorIdsByBuildingId = (buildingId) => {
    const floorResult = _.get(this.props, "floors", []).find(
      (f) => String(f.building.id) === String(buildingId)
    );
    const floorIds = _.get(floorResult, "floors", []).map((f) => String(f.id));

    return floorIds;
  };

  isSomeFloorsOfThisBuildingSelected = (buildingId) => {
    const { selectedFloors } = this.props;
    const floorIds = this.getFloorIdsByBuildingId(buildingId);
    const isSomeFloorsOfThisBuildingSelected = floorIds.some((floorId) => {
      return selectedFloors.includes(String(floorId));
    });

    return isSomeFloorsOfThisBuildingSelected;
  };

  isAllFloorsOfThisBuildingSelected = (buildingId) => {
    const { selectedFloors } = this.props;
    const floorIds = this.getFloorIdsByBuildingId(buildingId);
    const isAllFloorsOfThisBuildingSelected = floorIds.every((floorId) => {
      return selectedFloors.includes(String(floorId));
    });

    return isAllFloorsOfThisBuildingSelected;
  };

  isBuildingSelected = (buildingId) => {
    const { area } = this.state;
    const isThisBuildingIncludedInArea = area.includes(String(buildingId)); // Check if the building is included in the area

    return isThisBuildingIncludedInArea;
  };

  renderIndicatorForNotAllFloorsSelected = (buildingId) => {
    const isSomeFloorsOfThisBuildingSelected = this.isSomeFloorsOfThisBuildingSelected(
      buildingId
    );
    const isAllFloorsOfThisBuildingSelected = this.isAllFloorsOfThisBuildingSelected(
      buildingId
    );

    if (
      isSomeFloorsOfThisBuildingSelected &&
      !isAllFloorsOfThisBuildingSelected
    ) {
      // Only show when floors of this building are selected
      return <span className="item-text white--text">{" [...]"}</span>;
    }
  };

  renderAreaOptions = () => {
    return this.state.showAreaOptions ? (
      <React.Fragment>
        <div
          className="evacuee__areaoptions--overlay"
          onClick={this.toggleAreaOptions}
        />
        <div className="evacuee__areaoptions">
          <div className="evacuee__areaoptions-head">
            <h2>Select Building/Floors</h2>
            <button onClick={this.toggleAreaOptions}>
              <CloseIcon width={20} height={20} fill="white" />
            </button>
          </div>

          {/* ---------------- BEGIN FILTER ---------------- */}
          <ul className="evacuee__areaoptions--list">
            <h4 className="evacuee__areaoptions--list-title">My Area</h4>
            <li className="evacuee__areaoptions--listitem listitem-myarea">
              <label className="item-label">
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.MYAREA}
                  onChange={(e) => this.handleAreaChange(e)} // TODO
                  checked={this.state.area === AREA.MYAREA ? true : false}
                />
                <span className="item-overlay" />
                <span className="item-text">
                  {this.props.areas &&
                    this.props.areas.map((item, key) => {
                      return <p key={key}>{item}</p>;
                    })}
                </span>
              </label>
            </li>

            <li className="evacuee__areaoptions--listitem hidden">
              <label className="item-label">
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.EVERYONE}
                  onChange={(e) => this.handleAreaChange(e)}
                  checked={this.state.area === AREA.EVERYONE ? true : false}
                />
                <span className="item-overlay" />
                <span className="item-text">All Buildings</span>
              </label>
            </li>

            <h4 className="evacuee__areaoptions--list-title">
              or Select Buildings & Floors
            </h4>
            <div className="evacuee__areaoptions--childlist">
              {this.props.buildings.map((building) => {
                return (
                  <div
                    className="evacuee__areaoptions--childlistitem-wrapper"
                    key={building.buildingId + " bldg"}
                  >
                    <div className="evacuee__areaoptions--childlistitem childlistitem--building">
                      <label className="item-label">
                        <input
                          type="checkbox"
                          className="filled-in"
                          value={building.buildingId}
                          onChange={(e) => this.handleAreaChange(e)}
                          checked={
                            this.isBuildingSelected(
                              String(_.get(building, "buildingId"))
                            ) || this.state.area === AREA.EVERYONE
                              ? true
                              : false
                          }
                        />
                        <span className="item-overlay" />
                        <span className="item-text">{building.name}</span>

                        {this.renderIndicatorForNotAllFloorsSelected(
                          building.buildingId
                        )}
                      </label>

                      {/* BUTTON ARROW (Check if building have floors then appear the arrow) */}
                      {this.props.floors.map((item, idx) => {
                        return building.buildingId === item.building.id ? (
                          <div className="btn-collapse-box" key={idx}>
                            <button
                              key={item.building.id}
                              className="btn-collapse"
                              onClick={() => {
                                this.toggleFloor(building.buildingId);
                              }}
                            >
                              {this.state.toggleFloorbyId ===
                              building.buildingId ? (
                                <ArrowChevronIcon
                                  direction="up"
                                  width={32}
                                  height={32}
                                />
                              ) : (
                                <ArrowChevronIcon width={32} height={32} />
                              )}
                            </button>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                      {/* END BUTTON ARROW */}
                    </div>

                    {/* FLOOR */}
                    {this.props.floors.length === 0 ? (
                      <div className="col s12 center">
                        <b>No Floors</b>
                      </div>
                    ) : (
                      <>
                        {this.state.toggleFloorbyId === building.buildingId && (
                          <div className="floors-scroll">
                            <ul className="floors-list">
                              {this.props.floors.map((item) => {
                                return item.floors.map((floor) => {
                                  return building.buildingId ===
                                    item.building.id ? (
                                    <li
                                      className="evacuee__areaoptions--listitem listitem-floor"
                                      key={
                                        "floorSearch--" +
                                        item.building.id +
                                        floor.id
                                      }
                                    >
                                      <label className="item-label">
                                        <input
                                          type="checkbox"
                                          className="filled-in"
                                          value={floor.id}
                                          onChange={(e) =>
                                            this.handleFloorChange(floor.id)
                                          }
                                          checked={this.props.isFloorSelected(
                                            floor.id.toString()
                                          )}
                                        />
                                        <span className="item-overlay" />
                                        <span className="item-text">
                                          {floor.name}
                                        </span>
                                      </label>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                });
                              })}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                    {/* END FLOOR */}
                  </div>
                );
              })}
            </div>
          </ul>
          {/* ---------------- END FILTER ---------------- */}
        </div>
      </React.Fragment>
    ) : (
      false
    );
  };

  render() {
    return (
      <div className="evacsafety__menu">
        {this.renderAreaBtn()}
        {this.renderAreaOptions()}
        {this.renderAssistBtn()}
        {this.renderVisitorBtn()}
        {this.renderShowAllBtn()}
        {this.renderMessageAllBtn()}
      </div>
    );
  }
}

export default FilterControl;
