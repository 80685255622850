import React, { Fragment, Component } from "react";
import axios from "axios";
import _ from "lodash";
import ls from "local-storage";
import Layout from "../UI/Layout";
import XCircleIcon from "../../images/x-circle.svg";
import WhiteXCalendarIcon from "../../images/white-x-calendar.svg";
import BlackXCalendarIcon from "../../images/black-x-calendar.svg";
import BlackBuildingIcon from "../../images/black-building.svg";
import WardenRoleIcon from "../../images/warden-role-333333.svg";
import AreaIcon from "../../images/my-area.svg";
import DateRangeIcon from "../../images/end-date.svg";
import DisabilityIcon from "../../images/disability.svg";
import BackIcon from "../../images/back.svg";
import { AlertModal } from "../UI/Modal/AlertModal";
import {
  api_v2_update_assignment_status_url,
  api_v2_peep_list_url,
} from "../../config";
import { formatDate } from "../../utils";
import "./styles.scss";

class WardenAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertModal: {
        isOpen: false,
        status: "", // Withdrawn | Accepted | Declined
        variant: "", // success | error | warn
      },
      reasonInput: "",
      isDecline: false,
      reportInDetails: {},
      peepList: [],
    };
  }

  getReportInDetails = () => {
    const data = ls.get("reportInDetails");
    this.setState({
      reportInDetails: {
        ...data,
        dateFrom: formatDate(data.dateFrom),
        dateTo: formatDate(data.dateTo),
      },
    });
  };

  fetchPeepList = async () => {
    const res = await axios.get(
      `${api_v2_peep_list_url}/${this.props.match.params.wardenAssignmentUID}`
    );
    this.setState({ peepList: res.data });
  };

  componentDidMount() {
    this.fetchPeepList();

    if (_.isEmpty(ls.get("reportInDetails"))) {
      this.props.history.push("/reportinlist");
    } else {
      this.getReportInDetails();
    }
  }

  handleOpenAlertModal = (variant, status) => {
    this.setState({
      alertModal: {
        isOpen: true,
        status,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        status: "",
      },
    });
    this.redirectBackToReportInListPage();
  };

  toggleIsDecline = () => {
    this.setState((state) => ({ isDecline: !state.isDecline }));
  };

  setIsDeclineActive = () => this.setState({ isDecline: true });

  setIsDeclineNotActive = () => this.setState({ isDecline: false });

  handleChangeReason = (e) => {
    this.setState({ reasonInput: e.target.value });
  };

  redirectBackToReportInListPage = () => {
    this.props.history.push("/reportinlist");
  };

  handleAcceptAssignment = () => {
    axios
      .post(api_v2_update_assignment_status_url, {
        Warden_Assignment_UID: this.props.match.params.wardenAssignmentUID,
        Assignment_Status: "Accepted",
      })
      .then(() => this.handleOpenAlertModal("success", "Accepted"));
  };

  handleDeclineAssignment = () => {
    axios
      .post(api_v2_update_assignment_status_url, {
        Warden_Assignment_UID: this.props.match.params.wardenAssignmentUID,
        Assignment_Status: "Declined",
        Assignment_Declined_Note: this.state.reasonInput,
      })
      .then(() => this.handleOpenAlertModal("success", "Declined"));
  };

  renderAlertMessage = (status) => {
    if (status === "Accepted") {
      return (
        <Fragment>
          You have <span style={{ fontWeight: 700 }}>accepted</span> the Warden
          assignment.
        </Fragment>
      );
    } else if (status === "Declined") {
      return (
        <Fragment>
          You have <span style={{ fontWeight: 700 }}>declined</span> the Warden
          assignment.
        </Fragment>
      );
    }
  };

  render() {
    const {
      isDecline,
      reportInDetails,
      peepList,
      reasonInput,
      alertModal,
    } = this.state;
    const isReasonEmpty = Boolean(reasonInput.length === 0);

    return (
      <Layout>
        <div className="warden-assignment">
          <div className="warden-assignment__header">
            <button
              className="warden-assignment__top-back-btn"
              onClick={this.redirectBackToReportInListPage}
            >
              <img src={BackIcon} alt="Back" />
            </button>

            <div className="warden-assignment__title">
              Accept / Decline Warden Assignment
            </div>
          </div>

          <div className="warden-assignment__form">
            <div className="warden-assignment__form-section">
              <div className="warden-assignment__form-section-item">
                <div className="warden-assignment__form-label">Client Site</div>

                <div className="warden-assignment__form-info">
                  <img src={BlackBuildingIcon} alt="Client site" />
                  {_.get(reportInDetails, "clientSite", "-")}
                </div>
              </div>

              <div className="warden-assignment__form-section-item">
                <div className="warden-assignment__form-label">Role</div>

                <div className="warden-assignment__form-info">
                  <img src={WardenRoleIcon} alt="Role" />
                  {_.get(reportInDetails, "role", "-")}
                </div>
              </div>

              <div className="warden-assignment__form-section-item">
                <div className="warden-assignment__form-label">Area</div>

                <div className="warden-assignment__form-info">
                  <img src={AreaIcon} alt="Area" />

                  {_.get(reportInDetails, "area", "-")}
                </div>
              </div>

              <div className="warden-assignment__form-section-item">
                <div className="warden-assignment__form-label">Date Range</div>

                {_.get(reportInDetails, "dateFrom") &&
                _.get(reportInDetails, "dateTo") ? (
                  <div className="warden-assignment__form-info">
                    <img src={DateRangeIcon} alt="Date range" />
                    {`${reportInDetails.dateFrom} - ${reportInDetails.dateTo}`}
                  </div>
                ) : (
                  "-"
                )}

                <div className="warden-assignment__form-info">
                  <img src={DisabilityIcon} alt="Disability" />

                  <div className="warden-assignment__form-info-details">
                    <div className="warden-assignment__form-info-details-title">
                      PEEP Confirmation
                    </div>
                    <div className="warden-assignment__form-info-details-subtitle">
                      These are the people in your area with a P.E.E.P.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Peep list */}
            <div
              className="warden-assignment__peep-list"
              style={{ height: peepList.length > 0 ? "207px" : "100%" }}
            >
              {peepList.length > 0 ? (
                peepList.map((peep) => {
                  return (
                    <div className="warden-assignment__peep-list-item">
                      <div className="warden-assignment__peep-list-item--header">
                        <span className="warden-assignment__peep-list-item--header-item">
                          {`${peep.Person}`}
                        </span>
                        <span className="warden-assignment__peep-list-item--header-item">
                          {`(${peep.PEEP_Floor})`}
                        </span>
                      </div>
                      <p>{peep.PEEP_Summary}</p>
                    </div>
                  );
                })
              ) : (
                <div className="warden-assignment__peep-list-item">
                  There are no PEEP's in your area.
                </div>
              )}
            </div>

            {isDecline && (
              <div className="warden-assignment__form-section">
                <div className="warden-assignment__form-section-item">
                  <div className="warden-assignment__form-label">Reason</div>

                  <textarea
                    ref={this.reasonInputRef}
                    placeholder="Please enter a reason"
                    className="warden-assignment__form-reason-input"
                    rows="4"
                    onChange={this.handleChangeReason}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="warden-assignment__form-actions">
            <button
              className="warden-assignment__form-actions-secondary-btn"
              style={{
                border: isDecline ? "2px solid #333333" : "2px solid #CF2228",
                backgroundColor: isDecline ? "#ffffff" : "#CF2228",
                color: isDecline ? "#333333" : "#ffffff",
              }}
              onClick={
                isDecline ? this.setIsDeclineNotActive : this.setIsDeclineActive
              }
            >
              <img
                src={isDecline ? XCircleIcon : WhiteXCalendarIcon}
                alt="Secondary"
              />
              {isDecline ? "Back" : "Decline"}
            </button>

            <button
              className="warden-assignment__form-actions-primary-btn"
              style={{
                border:
                  isDecline && isReasonEmpty
                    ? "2px solid #E7E7E8"
                    : isDecline && !isReasonEmpty
                    ? "2px solid #CF2228"
                    : "2px solid #00B050",
                backgroundColor:
                  isDecline && isReasonEmpty
                    ? "#E7E7E8"
                    : isDecline && !isReasonEmpty
                    ? "#CF2228"
                    : "#00B050",
                color: isDecline && isReasonEmpty ? "#000000" : "#ffffff",
              }}
              onClick={
                isDecline
                  ? this.handleDeclineAssignment
                  : this.handleAcceptAssignment
              }
              disabled={isDecline && reasonInput === ""}
            >
              <img
                src={
                  isDecline && isReasonEmpty
                    ? BlackXCalendarIcon
                    : WhiteXCalendarIcon
                }
                alt="Primary"
              />
              {isDecline ? "Decline" : "Accept"}
            </button>
          </div>
        </div>

        <AlertModal
          isOpen={alertModal.isOpen}
          renderMessage={() => this.renderAlertMessage(alertModal.status)}
          variant={alertModal.variant}
          onClose={this.handleCloseAlertModal}
        />
      </Layout>
    );
  }
}

export default WardenAssignment;
