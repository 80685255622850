import React, { Component } from "react";
import { withRouter } from "react-router";
import ReportForm from "./ReportForm";
import ReportFormActions from "./ReportFormActions";
import {
  api_reportin_index_url,
  api_warden_signin_url,
  api_logout_url,
  api_warden_reportin_url,
  api_warden_reportna_url,
} from "../../config";
import axios from "axios";
import ls from "local-storage";
import Modal from "../Utilities/Modal";

import "./scss/reportin.scss";

class ReportIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_events: "",
      active_event: true,
      locations: [],
      role: [],
      area: [],
      peer_email: "",
      loading: true,
      sign_in_data: {
        event_uid: "",
        warden_uid: "",
        reported_in: false,
        on_duty: false,
        not_available: false,
      },
      info: {
        title: "",
        message: "",
        show: false,
        type: "check",
        showMenu: false,
        logout: false,
      },
      nav: {
        show: false,
      },
    };
  }

  loading = (isLoading) => {
    if (isLoading === true) {
      this.setState({
        ...this.state,
        loading: true,
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  /**
   * This method logs user out from the application
   * by deleting ther access token from the server
   * and also deleting local storage data
   */
  handleLogout = () => {
    this.setState(
      {
        ...this.state,
        info: {
          ...this.state.info,
          show: false,
        },
      },
      () => {
        axios
          .delete(api_logout_url)
          .then((resp) => {
            if (resp.data.status === "logout_success") {
              ls.clear();
              this.props.history.push("/");
            }
          })
          .catch((err) => {});
      }
    );
  };

  /**
   * This method is called when the warden
   * clicks Report In Button
   */

  handleReportIn = () => {
    axios.post(api_warden_reportin_url, null).then((resp) => {
      let info = null;
      if (resp.data.error) {
        info = {
          title: resp.data.error,
          message: resp.data.message,
          show: true,
          type: "cross",
          showMenu: true,
        };
        this.updateInfo(info);
      } else {
        this.setState({
          ...this.state,
          sign_in_data: {
            ...this.state.sign_in_data,
            reported_in: true,
            on_duty: true,
          },
        });
        this.props.showSideNav(true);
      }
    });
  };

  handleReportNa = () => {
    axios.post(api_warden_reportna_url, null).then((resp) => {
      let info = null;
      if (resp.data.error === undefined) {
        info = {
          title: resp.data.status,
          message: resp.data.message,
          show: true,
          logout: true,
          type: "cross",
          showMenu: false,
        };
      } else {
        info = {
          title: resp.data.error,
          message: resp.data.message,
          show: true,
          type: "cross",
          showMenu: true,
        };
      }
      this.setState({
        ...this.state,
        sign_in_data: {
          ...this.state.sign_in_data,
          reported_in: true,
          not_available: true,
        },
      });
      this.updateInfo(info);
    });
  };

  /**
   * Loads ReportIn data from the server
   * and logs warden sign in data
   */
  loadReportData = () => {
    axios
      .post(api_reportin_index_url, null)
      .then((resp) => {
        const report = resp.data;
        this.loading(false);
        this.setState({
          ...this.state,
          current_events: report.data.event,
          locations: report.data.locations,
          role: report.data.role,
          area: report.data.area,
          active_event: report.data.active_event,
        });
        this.logWardenSignIn();
        this.props.updateRole(this.state.role);
      })
      .catch((err) => {
        this.loading(false);
      });
  };

  /**
   * TODO : Check if it was a peer login
   */
  logWardenSignIn = () => {
    if (this.state.active_event) {
      const peer_email = ls.get("peer_email");
      this.loading(true);
      axios
        .post(
          api_warden_signin_url,
          peer_email === "" ? null : { peer_email: peer_email }
        )
        .then((resp) => {
          if (!resp.data.error) {
            this.setState({
              ...this.state,
              reported_in: resp.data.reported_in,
              sign_in_data: {
                warden_uid: resp.data.warden_uid,
                event_uid: resp.data.event_uid,
                reported_in: resp.data.reported_in,
                on_duty: resp.data.on_duty,
                not_available: resp.data.not_available,
              },
            });
            ls.set("on_duty", resp.data.on_duty);
          }
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
        });
    }
  };

  componentDidMount() {
    this.loadReportData();
    this.checkReportState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sign_in_data !== this.state.sign_in_data) {
      this.checkReportState();
    }
  }

  checkReportState = () => {
    if (this.state.sign_in_data.on_duty === true) {
      this.props.showBurgerMenu(true);
    } else if (this.state.active_event === false) {
      this.props.showBurgerMenu(false);
    } else {
      this.props.showBurgerMenu(false);
    }
    // this.props.showBackBtn(false);
  };

  /**
   * Method updates modal box info
   */
  updateInfo = (info) => {
    this.setState({
      ...this.state,
      info,
    });
  };

  /**
   * Method that handles closing of the
   * Error Modal box
   */
  closeInfoPopup = () => {
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        show: false,
      },
    });
  };

  showLoader = () => {
    if (this.state.loading) {
      return (
        <div className="loading-overlay">
          <div className="spinner" />
        </div>
      );
    }
  };

  render() {
    let infoType = "warning " + this.state.info.type;
    return (
      <React.Fragment>
        {this.showLoader()}
        <Modal
          id="warningPopup"
          show={this.state.info.show}
          close={this.closeInfoPopup}
        >
          <div className={infoType}>
            <p className="center-align">
              {this.state.info.title
                ? this.state.info.title.replace(/_/g, " ")
                : null}
            </p>
          </div>
          <div className="message col s12 valign-wrapper">
            <p className="center-text">{this.state.info.message}</p>
          </div>
          <div className="actions col s12">
            {this.state.info.showMenu ? (
              <button
                className="btn-small blue darken-2 full-ok"
                onClick={() => {
                  this.showSideNav(true);
                }}
              >
                Ok
              </button>
            ) : (
              <button
                className="btn-small blue darken-2 full-ok"
                onClick={
                  this.state.info.logout
                    ? this.handleLogout
                    : this.closeInfoPopup
                }
              >
                Ok
              </button>
            )}
          </div>
        </Modal>

        <ReportForm
          current_events={this.state.current_events}
          locations={this.state.locations}
          role={this.state.role}
          area={this.state.area}
          loading={this.state.loading}
          active_event={this.state.active_event}
        />
        <ReportFormActions
          loading={this.state.loading}
          reported_in={this.state.sign_in_data.reported_in}
          on_duty={this.state.sign_in_data.on_duty}
          not_available={this.state.sign_in_data.not_available}
          sign_in_data={this.state.sign_in_data}
          show={this.state.info.show}
          handleLogout={this.handleLogout}
          handleReportIn={this.handleReportIn}
          handleReportNa={this.handleReportNa}
          handleOffDuty={this.handleLogout}
          role={this.state.role}
          active_event={this.state.active_event}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(ReportIn);
