import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../../Routes";
import PeepPopUp from "./PeepPopUp";
import { MessageIcon, PhoneIcon } from "../../../Icons";

// This should be same with API requirement (can't use danger, safe like evacuee safety)
// Should be match with this values: this.props.pwdmi_statuses
export const STATUSES = {
  SAFE: "safe",
  SAFE_1: "Safe",
  SAFE_2: "safe on floor",
  SAFE_3: "Safe On Floor",
  UNKNOWN: "unknown",
  UNKNOWN_1: "Unknown",
  DANGER: "danger", //not allowed on the /api/floor/evacuation/updatePeepStatus
  DANGER_1: "In Danger",
};

class PeepListItem extends Component {
  state = {
    showPeepPopUp: false,
    showStatusModal: false,
  };

  openPeepPopUp = () => {
    this.setState({
      showPeepPopUp: true,
    });
  };

  closePeepPopUp = () => {
    this.setState({
      showPeepPopUp: false,
    });
  };

  toggleStatusModal = () => {
    this.setState({
      ...this.state,
      showStatusModal: !this.state.showStatusModal,
    });
  };

  statusVariants = () => {
    let statusText = "";
    let statusTextOn = "";
    let assist = this.props.info.special[0];
    let refusal = this.props.info.special[2];
    let status = this.props.info.status;

    switch (status) {
      case STATUSES.SAFE:
        statusText = "Safe";
        statusTextOn = "Safe on";
        break;
      case STATUSES.SAFE_1:
        statusText = "Safe";
        statusTextOn = "Safe on";
        break;
      case STATUSES.SAFE_2:
        statusText = "Safe";
        statusTextOn = "Safe on";
        break;
      case STATUSES.SAFE_3:
        statusText = "Safe";
        statusTextOn = "Safe on";
        break;
      case STATUSES.DANGER:
        statusText = "Danger";
        statusTextOn = "Danger on";
        break;
      case STATUSES.DANGER_1:
        statusText = "Danger";
        statusTextOn = "Danger on";
        break;
      case STATUSES.UNKNOWN_1:
        statusText = "Unknown";
        statusTextOn = "Unknown on";
        break;
      default:
        statusText = "Unknown";
        statusTextOn = "Unknown on";
        break;
    }

    if (assist.name === "assist" && assist.state && assist.detail) {
      return (
        <>
          <span className="status-text">{statusTextOn}</span>
          <span className="status-location">
            {assist.detail ? assist.detail : null}
          </span>
        </>
      );
    } else if (refusal.name === "refusal" && refusal.state && refusal.detail) {
      return (
        <>
          <span className="status-text">{statusTextOn}</span>
          <span className="status-location">
            {refusal.detail ? refusal.detail : null}
          </span>
        </>
      );
    } else {
      return <span className="status-text">{statusText}</span>;
    }
  };

  render() {
    let dangerDisabled =
      this.props.info.status === STATUSES.DANGER ? true : false;
    let safeDisabled = this.props.info.status === STATUSES.SAFE ? true : false;
    let unknownDisabled =
      this.props.info.status === STATUSES.UNKNOWN ? true : false;
    let statusClassName = this.props.info.status
      .replace(/\s+/g, "")
      .toLowerCase();

    return (
      <>
        <div className="card__peeplist">
          <div className="card__peeplist-left">
            <div className="card__peeplist-name">{this.props.info.name}</div>
            <div className="attr">
              <div className="card__peeplist-status">
                <button
                  className={`status status--${statusClassName}`}
                  onClick={() => {
                    this.toggleStatusModal();
                  }}
                >
                  {this.statusVariants()}
                </button>
                {this.state.showStatusModal && (
                  <>
                    <div className="status-modal">
                      {!safeDisabled ? (
                        <button
                          className="status status--safe"
                          data-status={this.props.info.status}
                          onClick={() => {
                            this.props.handleStatus(
                              this.props.info.id,
                              STATUSES.SAFE,
                              this.props.input.floor
                            );
                            this.toggleStatusModal();
                            setTimeout(() => {
                              this.props.refreshSearchEvacuees();
                            }, 2000);
                          }}
                        >
                          <span className="status-text">Safe on floor</span>
                        </button>
                      ) : null}
                      {!unknownDisabled ? (
                        <button
                          className="status status--unknown"
                          data-status={this.props.info.status}
                          onClick={() => {
                            this.props.handleStatus(
                              this.props.info.id,
                              STATUSES.UNKNOWN,
                              this.props.input.floor
                            );
                            this.toggleStatusModal();
                            setTimeout(() => {
                              this.props.refreshSearchEvacuees();
                            }, 2000);
                          }}
                        >
                          <span className="status-text">Unknown</span>
                        </button>
                      ) : null}
                      {!dangerDisabled ? (
                        <button
                          className="status status--danger"
                          data-status={this.props.info.status}
                          onClick={() => {
                            this.props.handleStatus(
                              this.props.info.id,
                              STATUSES.DANGER_1,
                              this.props.input.floor
                            );
                            this.toggleStatusModal();
                            setTimeout(() => {
                              this.props.refreshSearchEvacuees();
                            }, 2000);
                          }}
                        >
                          <span className="status-text">Danger on floor</span>
                        </button>
                      ) : null}
                    </div>
                    <div
                      className="status-modal-overlay"
                      onClick={() => {
                        this.toggleStatusModal();
                      }}
                    />
                  </>
                )}
              </div>
              <button className="btn-peep" onClick={this.openPeepPopUp}>
                Peep
              </button>
            </div>
          </div>
          <div className="card__peeplist-right">
            <div className="cta">
              <div className="btn-message">
                <Link
                  to={
                    route.message.evacuee.link +
                    "/" +
                    this.props.info.evacuee_list_uid +
                    "?from=" +
                    route.floor.evacuation.link
                  }
                >
                  <MessageIcon width="25px" height="25px" fill="white" />
                </Link>
              </div>
              <div className="btn-phone">
                <a href={`tel:${this.props.info.phone}`}>
                  <PhoneIcon width="25px" height="25px" fill="white" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* PEEP POPUP */}
        <PeepPopUp
          show={this.state.showPeepPopUp}
          name={this.props.info.name}
          details={this.props.info.details}
          close={this.closePeepPopUp}
        />
        {/* END PEEP POPUP */}
      </>
    );
  }
}

export default PeepListItem;
