import React from "react";
import ExclamationMark from "../../../../images/exclamation-mark.svg";
import "./styles.scss";

function CreateEventNotice(props) {
  const { variant, message, fontWeight } = props;

  return (
    <div
      style={{ fontWeight }}
      className={
        variant === "error"
          ? "create-event-notice--error"
          : "create-event-notice--warn"
      }
    >
      <span
        className={
          variant === "error"
            ? "create-event-notice__img-container--error"
            : "create-event-notice__img-container--warn"
        }
      >
        <img src={ExclamationMark} alt="" />
      </span>

      <span className="create-event-notice__content">{message}</span>
    </div>
  );
}

export default CreateEventNotice;
