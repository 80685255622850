import React, { Component } from "react";
import axios from "axios";
import Controls from "./Controls";
import PeepList from "./PeepList";
import OccupantList from "./Occupants";
import Loader from "../../Utilities/Loader";
import {
  api_floor_evacuation_data,
  api_floor_evacuation_floor_update,
  api_floor_evacuation_reported_update,
  api_v2_floor_evacuation_floor_status_update,
  api_floor_evacuation_peep_status_update,
  api_floor_evacuation_building_update,
  api_floor_evacuation_evacuee_paginated,
} from "../../../config";
import "./scss/evacuation.scss";

class FloorEvacuation extends Component {
  state = {
    loading: false, //overall component loading
    onlyPeepLoad: false, // only peep component loading
    loadSpinner: false, // overlay spinner loading
    lazyLoading: false, // lazy loading
    doLazyLoad: true, // flag to check if we want to lazyload
    noEvent: false, // flag to check if there is an event
    buildings: [], // all the buildings array list
    floors: [], // all the floors in the selected building array list
    floor_status: [], // all the floor status that are in use
    peep: [], // all the evacuees that have peep status
    persons: [], // all the evacuees in the building paginated
    pwdmi_statuses: [], // all the pwdmi_statuses that are in use
    input: {
      // users input
      building: 0, // selected building id
      floor: 0, // selected floor id
      status: 0, // selected floor_status id
    },
    pagination: {
      // evacuees pagination details
      next_page_url: null,
      total: null,
      from: null,
    },
    refusal_to_evacuate: 0,
    status_selected: {},
  };

  componentDidMount() {
    this.getInitialData();
    window.onscroll = (e) => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 150
      ) {
        this.lazyLoadEvacuees();
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * Dont fire the methonds on first update
     */
    if (prevState.input.floor !== 0) {
      if (prevState.input.floor !== this.state.input.floor) {
        this.handleFloorChange();
      } else if (prevState.input.building !== this.state.input.building) {
        this.handleBuildingChange();
      }
    }
  }

  loading = (loading) => {
    this.setState({
      ...this.state,
      loading,
    });
  };

  onlyPeepLoad = (onlyPeepLoad) => {
    this.setState({
      ...this.state,
      onlyPeepLoad,
    });
  };

  loadSpinner = (loadSpinner) => {
    this.setState({
      ...this.state,
      loadSpinner,
    });
  };

  /**
   * This method loads initial state of the component
   */
  getInitialData = () => {
    this.loading(true);
    axios
      .get(api_floor_evacuation_data)
      .then((resp) => {
        if (resp.data.error !== undefined) {
          this.setState({
            noEvent: true,
          });
        } else {
          this.setState({
            ...this.state,
            ...resp.data,
            buildings: resp.data.buildings,
            floor_status: resp.data.floor_status,
            floors: resp.data.floors,
            input: resp.data.input,
            pagination: {
              next_page_url: resp.data.pagination.next_page_url,
              total: resp.data.pagination.total,
              from: resp.data.pagination.from,
            },
            peep: resp.data.peep,
            persons: resp.data.persons,
            pwdmi_statuses: resp.data.pwdmi_statuses,
            noEvent: false,
            refusal_to_evacuate: resp.data.refusal_to_evacuate,
            status_selected: resp.data.floor_status.find(
              (obj) => obj.id === resp.data.input.status
            ),
          });
        }

        this.loading(false);
      })
      .catch((err) => {
        this.loading(false);
      });
  };

  getRefreshData = () => {
    this.loading(true);
    axios
      .get(api_floor_evacuation_data)
      .then((resp) => {
        if (resp.data.error !== undefined) {
          this.setState({
            noEvent: true,
          });
        } else {
          this.setState({
            peep: resp.data.peep,
            persons: resp.data.persons,
            refusal_to_evacuate: resp.data.refusal_to_evacuate,
          });
        }
        this.loading(false);
      })
      .catch((err) => {
        this.loading(false);
      });
  };

  loadPaginatedEvacuees = () => {
    this.setState(
      {
        ...this.state,
        lazyLoading: true,
      },
      () => {
        axios
          .get(api_floor_evacuation_evacuee_paginated)
          .then((resp) => {
            this.setState({
              ...this.state,
              persons: resp.data.persons,
              pagination: {
                next_page_url: resp.data.pagination.next_page_url,
                from: resp.data.pagination.from,
                total: resp.data.pagination.total,
              },
              lazyLoading: false,
            });
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              lazyLoading: false,
            });
          });
      }
    );
  };

  lazyLoadEvacuees = () => {
    if (
      this.state.pagination.next_page_url !== null &&
      !this.state.lazyLoading &&
      this.state.doLazyLoad
    ) {
      this.setState(
        {
          ...this.state,
          lazyLoading: true,
        },
        () => {
          axios
            .get(this.state.pagination.next_page_url)
            .then((resp) => {
              let newPersons = [...this.state.persons];
              newPersons.push(...resp.data.persons);
              this.setState({
                ...this.state,
                persons: newPersons,
                pagination: {
                  next_page_url: resp.data.pagination.next_page_url,
                  from: resp.data.pagination.from,
                  total: resp.data.pagination.total,
                },
                lazyLoading: false,
              });
            })
            .catch((err) => {
              this.setState({
                ...this.state,
                lazyLoading: false,
              });
            });
        }
      );
    }
  };

  renderLazyLoadInfo = () => {
    let last = false;
    let loading = false;
    if (
      this.state.pagination !== null &&
      this.state.pagination.next_page_url === null
    ) {
      last = true;
    }
    if (this.state.lazyLoading) {
      loading = true;
    }

    return (
      <React.Fragment>
        {last ? (
          <div className="container">
            <div className="not-found">
              <p className="not-found-title">You have reached the end.</p>
            </div>
          </div>
        ) : null}
        {loading ? (
          <div className="container">
            <div className="not-found">
              <p className="not-found-title">Loading...</p>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  setEvacueesList = (data) => {
    this.setState({
      ...this.state,
      persons: data.persons,
      pagination: {
        next_page_url: data.pagination.next_page_url,
        total: data.pagination.total,
        from: data.pagination.from,
      },
    });
  };

  setPeepsList = (data) => {
    this.setState({
      ...this.state,
      peep: data.peep,
    });
  };

  /**
   * This method is fired when the update floor status button
   * is clicked, it updates the selected floor's status to
   * that of current selected status
   */
  handleFloorStatusUpdate = (e) => {
    this.loadSpinner(true);
    axios
      .post(api_v2_floor_evacuation_floor_status_update, {
        floorId: this.state.input.floor,
        floorStatusId: this.state.input.status,
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          this.updateFloorStatusToInput();
        }
        this.loadSpinner(false);
      })
      .catch((err) => {
        this.loadSpinner(false);
      });
  };

  updateFloorStatusToInput = () => {
    let newFloorArray = [...this.state.floors];
    newFloorArray.forEach((floor) => {
      if (floor.id === this.state.input.floor) {
        floor.status = this.state.input.status;
      }
    });
    this.setState({
      ...this.state,
      floors: [...newFloorArray],
    });
  };

  handlePeepStatusChange = (peepId, status) => {
    let updatedPeep = [...this.state.peep];
    if (updatedPeep.length > 0) {
      updatedPeep.forEach((peep) => {
        if (peep.id === peepId) {
          peep.status = status;
        }
      });
      this.setState({
        ...this.state,
        peep: updatedPeep,
      });
    }
  };

  updatePeepStatus = (peepId, status) => {
    this.loadSpinner(true);
    axios
      .post(api_floor_evacuation_peep_status_update, {
        personId: peepId,
        status,
        floorId: this.state.input.floor,
      })
      .then((resp) => {
        this.handlePeepStatusChange(peepId, status);
        this.getRefreshData();
        this.loadSpinner(false);
      })
      .catch((err) => {
        this.loadSpinner(false);
      });
  };

  updateOccupantStatus = (peepId, status) => {
    this.loadSpinner(true);
    axios
      .post(api_floor_evacuation_peep_status_update, {
        personId: peepId,
        status,
        floorId: this.state.input.floor,
      })
      .then((resp) => {
        this.handlePeepStatusChange(peepId, status);
        this.getRefreshData();
        this.loadSpinner(false);
      })
      .catch((err) => {
        this.loadSpinner(false);
      });
  };

  /**
   * This method is fired when the report toggle switch
   * is changed in person list item
   */
  handleReportedChange = (personId) => {
    let newPersonList = [];
    this.state.persons.forEach((person) => {
      if (person.id === personId) {
        person.reported = !person.reported;
      }
      newPersonList.push(person);
    });
    this.setState({
      ...this.state,
      persons: newPersonList,
    });

    axios
      .post(api_floor_evacuation_reported_update, {
        person: personId,
        floorId: this.state.input.floor,
      })
      .then((resp) => {
        if (resp.data.status === "success" && resp.data.action === "add") {
          this.setState({
            ...this.state,
            // persons: newPersonList,
            refusal_to_evacuate: this.state.refusal_to_evacuate + 1,
          });
          this.getRefreshData();
        } else if (
          resp.data.status === "success" &&
          resp.data.action === "sub"
        ) {
          this.setState({
            ...this.state,
            // persons: newPersonList,
            refusal_to_evacuate: this.state.refusal_to_evacuate - 1,
          });
          this.getRefreshData();
        } else {
          console.log(resp.data);
        }
      })
      .catch((err) => {});
  };

  /**
   * Handle change in control component's input and
   * send the change to the server
   */
  handleChange = (e) => {
    const event = e.target;
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          [e.target.name]: parseInt(e.target.value, 10),
        },
      },
      () => {
        if (event.name === "status") {
          this.handleFloorStatusUpdate();
        }
      }
    );
  };

  handleChangeCustomStatusDropdown = (a, b) => {
    this.setState(
      {
        input: {
          ...this.state.input,
          [a]: parseInt(b, 10),
        },
      },
      () => {
        if (a === "status") {
          this.handleFloorStatusUpdate();
        }
        this.setState({
          status_selected: this.state.floor_status.find(
            (obj) => obj.id === this.state.input.status
          ),
        });
      }
    );
  };

  /**
   * This method is fired when the floor select's value
   * is changed, it updates the peep list with the
   * current selected floor's
   */
  handleFloorChange = () => {
    this.onlyPeepLoad(true);
    axios
      .post(api_floor_evacuation_floor_update, {
        floor: this.state.input.floor,
      })
      .then((resp) => {
        let floors = [...this.state.floors];
        floors.forEach((floor) => {
          if (floor.id === this.state.input.floor) {
            floor.status = resp.data.status;
          }
        });
        this.setState({
          ...this.state,
          peep: [...resp.data.peep],
          floors: floors,
          status_selected: this.state.floor_status.find(
            (obj) => obj.id === resp.data.status
          ),
        });
        this.updateInputFloorStatus();
        this.onlyPeepLoad(false);
      })
      .catch((err) => {
        this.onlyPeepLoad(false);
      });
  };

  updateInputFloorStatus = () => {
    let selectedFloor = this.state.floors.find((floor) => {
      return this.state.input.floor === floor.id;
    });
    this.setState({
      ...this.state,
      input: {
        ...this.state.input,
        status: selectedFloor.status,
      },
    });
  };

  handleBuildingChange = () => {
    this.loading(true);
    axios
      .post(api_floor_evacuation_building_update, {
        building: this.state.input.building,
      })
      .then((resp) => {
        this.setState({
          ...this.state,
          ...resp.data,
        });
        this.loading(false);
      })
      .catch((error) => {
        this.loading(false);
      });
  };

  renderOccupantList = () => {
    return (
      <OccupantList
        loading={this.state.loading}
        occupants={this.state.persons}
        input={this.state.input}
        handleStatus={this.updateOccupantStatus}
        refusal_to_evacuate={this.state.refusal_to_evacuate}
        handleReportedChange={this.handleReportedChange}
        setEvacueesList={this.setEvacueesList}
        loadPaginatedEvacuees={this.loadPaginatedEvacuees}
        selectedFloor={this.state.input.floor}
      />
    );
  };

  renderIfEvent = () => {
    if (!this.state.noEvent) {
      return (
        <div>
          <Controls
            loading={this.state.loading}
            buildings={this.state.buildings}
            floors={this.state.floors}
            floor_status={this.state.floor_status}
            input={this.state.input}
            status_selected={this.state.status_selected}
            handleChange={this.handleChange}
            handleFloorChange={this.handleFloorChange}
            handleBuildingChange={this.handleBuildingChange}
            handleFloorStatusUpdate={this.handleFloorStatusUpdate}
            handleChangeCustomStatusDropdown={
              this.handleChangeCustomStatusDropdown
            }
          />
          <PeepList
            loading={this.state.loading}
            onlyPeepLoad={this.state.onlyPeepLoad}
            peep={this.state.peep}
            pwdmi_statuses={this.state.pwdmi_statuses}
            input={this.state.input}
            handleStatus={this.updatePeepStatus}
            setPeepsList={this.setPeepsList}
            selectedFloor={this.state.input.floor}
          />
          {this.renderOccupantList()}
          {!this.state.loading && !this.state.loadSpinner
            ? this.renderLazyLoadInfo()
            : null}
        </div>
      );
    } else {
      return "NO EVENT";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.loadSpinner} />
        {this.renderIfEvent()}
      </React.Fragment>
    );
  }
}

export default FloorEvacuation;
