import React, { Fragment, Component } from "react";
import axios from "axios";
import _ from "lodash";
import ls from "local-storage";
import Layout from "../UI/Layout";
import XCircleIcon from "../../images/x-circle.svg";
import BlackXCalendarIcon from "../../images/black-x-calendar.svg";
import WhiteXCalendarIcon from "../../images/white-x-calendar.svg";
import BlackBuildingIcon from "../../images/black-building.svg";
import WardenRoleIcon from "../../images/warden-role-333333.svg";
import MyAreaIcon from "../../images/my-area.svg";
import EndDateIcon from "../../images/end-date.svg";
import { AlertModal } from "../UI/Modal/AlertModal";
import { api_v2_update_assignment_status_url } from "../../config";
import { formatDate } from "../../utils";
import "./styles.scss";

class Withdraw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reasonInput: "",
      reportInDetails: {},
      alertModal: {
        isOpen: false,
        status: "", // Withdrawn | Accepted | Declined
        variant: "", // success | error | warn
      },
    };
  }

  getReportInDetails = () => {
    const data = ls.get("reportInDetails");
    this.setState({
      reportInDetails: {
        ...data,
        dateFrom: formatDate(data.dateFrom),
        dateTo: formatDate(data.dateTo),
      },
    });
  };

  componentDidMount() {
    if (_.isEmpty(ls.get("reportInDetails"))) {
      this.props.history.push("/reportinlist");
    } else {
      this.getReportInDetails();
    }
  }

  handleOpenAlertModal = (variant, status) => {
    this.setState({
      alertModal: {
        isOpen: true,
        status,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        status: "",
      },
    });
    this.redirectBackToReportInListPage();
  };

  handleChangeReason = (e) => {
    this.setState({ reasonInput: e.target.value });
  };

  redirectBackToReportInListPage = () => {
    this.props.history.push("/reportinlist");
  };

  handleWithdraw = () => {
    axios
      .post(api_v2_update_assignment_status_url, {
        Warden_Assignment_UID: this.props.match.params.wardenAssignmentUID,
        Assignment_Status: "Withdrawn",
        Assignment_Declined_Note: this.state.reasonInput,
      })
      .then(() => this.handleOpenAlertModal("success", "Withdrawn"));
  };

  renderAlertMessage = (status) => {
    if (status === "Withdrawn") {
      return (
        <Fragment>
          You have <span style={{ fontWeight: 700 }}>withdrawn</span> the Warden
          assignment.
        </Fragment>
      );
    }
  };

  render() {
    const { reasonInput, reportInDetails, alertModal } = this.state;
    const isReasonEmpty = Boolean(reasonInput.length === 0);

    return (
      <Layout>
        <div className="withdraw">
          <div className="withdraw__header">
            <div className="withdraw__title">
              Withdraw from Warden Assignment
            </div>
          </div>

          <div className="withdraw__form">
            <div>
              <div className="withdraw__form-label">Client Site</div>

              <div className="withdraw__form-info">
                <img src={BlackBuildingIcon} alt="Client site" />
                {_.get(reportInDetails, "clientSite", "-")}
              </div>
            </div>

            <div>
              <div className="withdraw__form-label">Role</div>

              <div className="withdraw__form-info">
                <img src={WardenRoleIcon} alt="Role" />
                {_.get(reportInDetails, "role", "-")}
              </div>
            </div>

            <div>
              <div className="withdraw__form-label">My Area</div>

              <div className="withdraw__form-info">
                <img src={MyAreaIcon} alt="My area" />
                {_.get(reportInDetails, "area", "-")}
              </div>
            </div>

            <div>
              <div className="withdraw__form-label">Date Range</div>

              {_.get(reportInDetails, "dateFrom") &&
              _.get(reportInDetails, "dateTo") ? (
                <div className="withdraw__form-info">
                  <img src={EndDateIcon} alt="Date Range" />
                  {`${reportInDetails.dateFrom} - ${reportInDetails.dateTo}`}
                </div>
              ) : (
                "-"
              )}
            </div>

            <div>
              <div className="withdraw__form-label">Reason</div>

              <textarea
                ref={this.reasonInputRef}
                placeholder="Please enter a reason"
                className="withdraw__form-reason-input"
                rows="4"
                onChange={this.handleChangeReason}
              />
            </div>
          </div>

          <div className="withdraw__form-actions">
            <button
              className="withdraw__back-btn"
              onClick={this.redirectBackToReportInListPage}
            >
              <img src={XCircleIcon} alt="Withdraw" />
              Back
            </button>

            <button
              className="withdraw__primary-btn"
              style={{
                border: isReasonEmpty
                  ? "2px solid #E7E7E8"
                  : "2px solid #CF2228",
                backgroundColor: isReasonEmpty ? "#E7E7E8" : "#CF2228",
                color: isReasonEmpty ? "#000000" : "#ffffff",
              }}
              onClick={this.handleWithdraw}
              disabled={isReasonEmpty}
            >
              <img
                src={isReasonEmpty ? BlackXCalendarIcon : WhiteXCalendarIcon}
                alt="Withdraw"
              />
              Withdraw
            </button>
          </div>
        </div>

        <AlertModal
          isOpen={alertModal.isOpen}
          renderMessage={() => this.renderAlertMessage(alertModal.status)}
          variant={alertModal.variant}
          onClose={this.handleCloseAlertModal}
        />
      </Layout>
    );
  }
}

export default Withdraw;
