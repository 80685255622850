import React from "react";
import "./styles.scss";

function BroadcastMessageAndAlertToWardenButtons(props) {
  const {
    broadcastMessage,
    alertToWarden,
    handleChangeBroadcastMessage,
    handleChangeAlertToWarden,
    isDisableBroadcastMessageButton,
    showAlertToWardenToggle,
  } = props;

  return (
    <div className="broadcast-message-and-alert-to-warden-container">
      <div className="broadcast-message-container">
        <span className="broadcast-message-container__label">
          Send Broadcast message?
        </span>

        <span className="broadcast-message-container__actions">
          <button
            className="broadcast-message-container__actions-btn"
            style={{
              border:
                broadcastMessage === "yes"
                  ? "2px solid #00B050"
                  : "2px solid #e8e9ed",
              backgroundColor:
                broadcastMessage === "yes" ? "#00B050" : "#E7E7E8",
              color: broadcastMessage === "yes" ? "#ffffff" : "#969bab",
            }}
            onClick={() => handleChangeBroadcastMessage("yes")}
            disabled={isDisableBroadcastMessageButton}
          >
            Everyone
          </button>

          <button
            className="broadcast-message-container__actions-btn"
            style={{
              border:
                broadcastMessage === "no"
                  ? "2px solid #00B050"
                  : "2px solid #e8e9ed",
              backgroundColor:
                broadcastMessage === "no" ? "#00B050" : "#E7E7E8",
              color: broadcastMessage === "no" ? "#ffffff" : "#969bab",
            }}
            onClick={() => handleChangeBroadcastMessage("no")}
            disabled={isDisableBroadcastMessageButton}
          >
            No one
          </button>
        </span>
      </div>

      {showAlertToWardenToggle && (
        <div className="alert-to-warden-container">
          <span className="alert-to-warden-container__label">
            Send SMS Alert to Wardens?
          </span>

          <span className="alert-to-warden-container__actions">
            <button
              className="alert-to-warden-container__actions-btn"
              style={{
                border:
                  alertToWarden === "yes"
                    ? "2px solid #00B050"
                    : "2px solid #e8e9ed",
                backgroundColor:
                  alertToWarden === "yes" ? "#00B050" : "#E7E7E8",
                color: alertToWarden === "yes" ? "#ffffff" : "#969bab",
              }}
              onClick={() => handleChangeAlertToWarden("yes")}
              disabled={isDisableBroadcastMessageButton}
            >
              Yes
            </button>

            <button
              className="alert-to-warden-container__actions-btn"
              style={{
                border:
                  alertToWarden === "no"
                    ? "2px solid #00B050"
                    : "2px solid #e8e9ed",
                backgroundColor: alertToWarden === "no" ? "#00B050" : "#E7E7E8",
                color: alertToWarden === "no" ? "#ffffff" : "#969bab",
              }}
              onClick={() => handleChangeAlertToWarden("no")}
              disabled={isDisableBroadcastMessageButton}
            >
              No
            </button>
          </span>
        </div>
      )}
    </div>
  );
}

export default BroadcastMessageAndAlertToWardenButtons;
