import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { route } from "../../Routes";
import MessengerList from "../AllMessage/MessengerList";
import MessageBox from "../Template/MessageBox";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";
import Loader from "../../Utilities/Loader";
import { AlertModal } from "../../UI/Modal/AlertModal";
import { ArrowChevronIcon } from "../../Icons";
import PhoneLinkIcon from "../../../images/icon-phonelink.png";
import {
  api_message_group_send,
  api_v2_warden_send_report_in_links,
} from "../../../config";
import "./scss/groupmessage.scss";

class GroupMessaging extends Component {
  state = {
    loading: false,
    sms_allowed: false,
    toast: {
      show: false,
      type: TOAST_TYPE.SUCCESS,
      message: "",
    },
    audience: [],
    unknownAudiences: [],
    alertModal: {
      isOpen: false,
      message: "",
      variant: "", // success | error | warn
    },
  };

  componentDidMount() {
    const { audience, sms_allowed } = this.props.location.state;

    if (audience !== undefined && audience.length > 0) {
      this.setState({
        audience,
        sms_allowed,
      });
    }
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });
  };

  getAudienceIdArray = () => {
    let audienceIds = [];

    this.state.audience.forEach((warden) => {
      audienceIds.push(warden.id);
    });

    return audienceIds;
  };

  send = (message) => {
    let params = {
      audience: this.getAudienceIdArray(),
      message: message,
    };

    this.setState({
      ...this.state,
      loading: true,
    });

    axios
      .post(api_message_group_send, params)
      .then((resp) => {
        if (resp.data.status === "message_sent") {
          this.setState({
            ...this.state,
            loading: false,
            toast: {
              type: TOAST_TYPE.SUCCESS,
              show: true,
              message: resp.data.message,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
          toast: {
            type: TOAST_TYPE.DANGER,
            show: true,
            message: "Failed to send message",
          },
        });
      });
  };

  inAppCount = () => {
    let inApp = this.state.audience.filter((warden) => {
      return warden.status.toLowerCase() === "on duty";
    });

    return inApp.length;
  };

  inSmsCount = () => {
    let inSms = this.state.audience.filter((warden) => {
      return warden.status.toLowerCase() !== "on duty";
    });

    return inSms.length;
  };

  canSend = () => {
    let allowed = false;

    if (this.state.sms_allowed) {
      allowed = true;
    } else {
      if (this.inSmsCount() > 0) {
        allowed = false;
      } else {
        allowed = true;
      }
    }
    return allowed;
  };

  closeToast = () => {
    this.setState({
      ...this.state,
      toast: {
        ...this.state.toast,
        show: false,
      },
    });
  };

  handleSendReportInLink = async () => {
    const { senderWardenSignInUid } = this.props.location.state;
    const { audience } = this.state;

    if (_.get(audience, "length", 0) > 0) {
      await axios
        .post(api_v2_warden_send_report_in_links, {
          warden_sign_in_uids: audience.map((a) => a.wardenSignInUid),
          sender_warden_sign_in_uid: senderWardenSignInUid,
        })
        .then((res) =>
          this.handleOpenAlertModal(
            `${res.data.recordsInserted} Report-in links sent.`,
            "success"
          )
        );
    }
  };

  renderTopBar = () => {
    return (
      <div className="groupmsg__head">
        <div className="groupmsg__head-back">
          <Link
            to={
              this.props.location && !!this.props.location.from
                ? this.props.location.from
                : route.message.all.link
            }
            className="groupmsg__head-back--btn"
          >
            <ArrowChevronIcon
              direction="left"
              width={32}
              height={32}
              fill="white"
            />
          </Link>
        </div>
        <div className="groupmsg__head-warden">
          <div className="box-left">
            <div className="count">
              <span className="count-name">In App</span>
              <span className="count-number">{this.inAppCount()}</span>
            </div>
            <div className="count">
              <span className="count-name">SMS</span>
              <span className="count-number">{this.inSmsCount()}</span>
            </div>
            <div className="count">
              <span className="count-name">Total</span>
              <span className="count-number">{this.state.audience.length}</span>
            </div>
          </div>
          <div className="box-right">
            <button
              className="box-right-phonelink"
              onClick={this.handleSendReportInLink}
            >
              <img src={PhoneLinkIcon} alt="phone link icon" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderAudienceList = () => {
    return (
      <div className="bottom__marginfix">
        <MessengerList list={this.state.audience} type="broadcast" />
      </div>
    );
  };

  renderMessageBox = () => {
    return (
      <MessageBox
        loading={this.state.loading}
        send={this.send}
        canSend={this.canSend()}
        sms={this.state.sms_allowed}
        group={true}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <Toast
          show={this.state.toast.show}
          close={this.closeToast}
          toastType={this.state.toast.type}
          delay={2000}
        >
          {this.state.toast.message}
        </Toast>
        {this.renderTopBar()}
        {this.renderAudienceList()}
        {this.renderMessageBox()}

        <AlertModal
          isOpen={this.state.alertModal.isOpen}
          message={this.state.alertModal.message}
          variant={this.state.alertModal.variant}
          onClose={this.handleCloseAlertModal}
        />
      </React.Fragment>
    );
  }
}

export default GroupMessaging;
