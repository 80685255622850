import React, { Component } from "react";
import SquareEmptyCheckboxIcon from "../../../../images/square-empty-checkbox.svg";
import "./styles.scss";

class CheckboxInput extends Component {
  render() {
    const { checked, checkedIcon, onChange, label, style } = this.props;

    return (
      <div className="checkbox-input" style={style}>
        <label className="checkbox-input__container">
          <input checked={checked} onChange={onChange} type="checkbox" />

          {checked ? (
            checkedIcon
          ) : (
            <img
              className="checkbox-input__empty-checked-img"
              src={SquareEmptyCheckboxIcon}
              alt=""
            />
          )}
        </label>

        {label}
      </div>
    );
  }
}

export default CheckboxInput;
