import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import ls from "local-storage";
import onlyLoggedIn from "../Routes/Guards/onlyLoggedIn";
import onlyGuest from "../Routes/Guards/onlyGuest";

/**
 * Components
 */
import Login from "../Login";
import ReportInList from "../ReportInList";
import ReportIn from "../ReportIn";
import WardenAssignment from "../WardenAssignment";
import Withdraw from "../Withdraw";
import Event from "../Event/Create";
import FloorWardenStatus from "../Floor/WardenStatus";
import FloorEvacuation from "../Floor/Evacuation";
import WardensOnDuty from "../Warden/Onduty";
import Role from "../Me/Role";
import BuildingStatus from "../Building/Status";
import EvacueeSafety from "../Evacuee/v2/Safety";
import FloorEvacuationStatus from "../Floor/EvacStatus";
import EvacueeMessaging from "../Message/EvacueeMessage";
import EvacueeGroupMessaging from "../Message/EvacueeGroupMessage";
import WardenMessaging from "../Message/WardenMessage";
import AllMessage from "../Message/AllMessage";
import GroupMessaging from "../Message/GroupMessage";
import NotFound from "../Errors/NotFound";

import Shell from "../Shell";

/**
 * Route names
 */
export const route = {
  shell: { title: "Evac Management", link: "/" },
  login: { title: "Login", link: "/login" },
  reportinlist: {
    title: "Report In List",
    link: "/reportinlist",
  },
  reportin: {
    title: "Report In",
    link: "/reportin",
  },
  wardenassignment: {
    title: "Warden Assignment",
    link: "/warden-assignment/:wardenAssignmentUID",
  },
  withdraw: {
    title: "Withdraw",
    link: "/withdraw/:wardenAssignmentUID",
  },
  evacuee: {
    safety: {
      title: "Evacuee Safety",
      link: "/evacuee/safety",
    },
  },

  event: {
    management: {
      title: "Event Management",
      link: "/event/management",
    },
  },

  floor: {
    warden: {
      status: {
        title: "Floor Warden Status",
        link: "/floor/warden/status",
      },
    },
    evacuation: {
      title: "Floor Evacuation",
      link: "/floor/evacuation",
    },
    evacstatus: {
      title: "Floor Evac. Status",
      link: "/floor/evacstatus",
    },
  },
  building: {
    status: { title: "Evacuation Status", link: "/building/status" },
  },
  warden: {
    onduty: { title: "Wardens On duty", link: "/warden/onduty" },
  },
  message: {
    evacuee: {
      title: "Evacuee Messaging",
      link: "/message/evacuee",
    },
    evacueegroup: {
      title: "Evacuee Messaging",
      link: "/message/evacueegroup",
    },
    all: {
      title: "Warden Messaging",
      link: "/message/all",
    },
    warden: {
      title: "Warden Messaging",
      link: "/message/warden",
    },
    group: {
      title: "Warden Messages",
      link: "/message/group",
    },
  },
  me: {
    role: { title: "My Role", link: "/me/role" },
    messages: { title: "My Messages", link: "/me/messages" },
  },
};
/**
 * Specifying Route Guards before rendering
 */
const LoginComponent = onlyGuest(Login);
const ReportInListComponent = onlyLoggedIn(ReportInList);
const ReportInComponent = onlyLoggedIn(ReportIn);
const WithdrawComponent = onlyLoggedIn(Withdraw);
const EventComponent = onlyLoggedIn(Event);
const FloorWardenStatusComponent = onlyLoggedIn(FloorWardenStatus);
const WardensOnDutyComponent = onlyLoggedIn(WardensOnDuty);
const FloorEvacuationComponent = onlyLoggedIn(FloorEvacuation);
const MyRoleComponent = onlyLoggedIn(Role);
const BuildingStatusComponent = onlyLoggedIn(BuildingStatus);
const EvacueeSafetyComponent = onlyLoggedIn(EvacueeSafety);
const FloorEvacuationStatusComponent = onlyLoggedIn(FloorEvacuationStatus);
const AllMessageComponent = onlyLoggedIn(AllMessage);
const WardenMessageComponent = onlyLoggedIn(WardenMessaging);
const EvacueeMessageComponent = onlyLoggedIn(EvacueeMessaging);
const EvacueeGroupMessageComponent = onlyLoggedIn(EvacueeGroupMessaging);
const GroupMessageComponent = onlyLoggedIn(GroupMessaging);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module_access: null,
      show_burger_menu: true,
      show_side_nav: false,
      show_back_btn: false,
    };
  }

  componentDidMount() {
    // if (ls.get("module_access") !== null) {
    //   let role = ls.get("module_access");
    //   this.setState({
    //     ...this.state,
    //     module_access: role,
    //   });
    // } else {
    //   ls.remove("me");
    //   ls.remove("module_access");
    //   this.props.history.push(route.login.link);
    // }
    if (ls.get("auth") !== null) {
      let role = ls.get("auth");
      this.setState({
        ...this.state,
        module_access: role,
      });
    } else {
      ls.remove("me");
      ls.remove("auth");
      this.props.history.push(route.login.link);
    }
  }

  updateMe = (myInfo) => {
    this.setState(
      {
        ...this.state,
        me: myInfo,
      },
      () => {
        ls.set("me", this.state.me);
      }
    );
  };

  updateRole = (roleInfo) => {
    this.setState(
      {
        ...this.state,
        module_access: roleInfo,
      },
      () => {
        ls.set("module_access", this.state.module_access);
      }
    );
  };

  showBurgerMenu = (show, callback = null) => {
    this.setState(
      {
        ...this.state,
        show_burger_menu: show,
      },
      callback !== null ? callback : null
    );
  };

  showBackBtn = (show, callback = null) => {
    this.setState(
      {
        ...this.state,
        show_back_btn: show,
      },
      callback !== null ? callback : null
    );
  };

  showSideNav = (show, callback = null) => {
    this.setState(
      {
        ...this.state,
        show_side_nav: show,
      },
      () => {
        if (callback && typeof callback === typeof Function) {
          callback();
        }
      }
    );
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={route.login.link}
          render={(routeProps) => (
            <LoginComponent {...routeProps} updateMe={this.updateMe} />
          )}
        />

        <Route path={route.shell.link}>
          <Shell
            showBurgerMenu={this.state.show_burger_menu}
            showNav={this.state.show_side_nav}
            showSideNav={this.showSideNav}
            showBackBtn={this.state.show_back_btn}
            hideHeader={[
              `/${route.reportinlist.link.split("/")[1]}`,
              `/${route.wardenassignment.link.split("/")[1]}`,
              `/${route.withdraw.link.split("/")[1]}`,
            ]}
          >
            <Switch>
              <Redirect
                exact
                path={route.shell.link}
                to={route.reportinlist.link}
              />

              <Route
                exact
                path={route.reportinlist.link}
                render={(routeProps) => (
                  <ReportInListComponent
                    {...routeProps}
                    updateRole={this.updateRole}
                  />
                )}
              />

              <Route
                exact
                path={route.reportin.link}
                render={(routeProps) => (
                  <ReportInComponent
                    {...routeProps}
                    showBurgerMenu={this.showBurgerMenu}
                    showBackBtn={this.showBackBtn}
                    showSideNav={this.showSideNav}
                    me={this.state.me}
                    access={this.state.role}
                    updateRole={this.updateRole}
                  />
                )}
              />

              <Route
                exact
                path={route.wardenassignment.link}
                render={(routeProps) =>
                  ls.get("auth") !== null ? (
                    <WardenAssignment {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.withdraw.link}
                render={(routeProps) =>
                  ls.get("auth") !== null ? (
                    <WithdrawComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.event.management.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Event_Management ? (
                    <EventComponent
                      {...routeProps}
                      me={this.state.me}
                      showBackBtn={this.showBackBtn}
                    />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              {/* <Redirect to={window.document.referrer} /> */}

              <Route
                exact
                path={route.floor.warden.status.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Floor_Warden_Status ? (
                    <FloorWardenStatusComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.warden.onduty.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Wardens_On_Duty ? (
                    <WardensOnDutyComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.floor.evacuation.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Floor_Evacuation ? (
                    <FloorEvacuationComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.building.status.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Building_Evacuation_Status ? (
                    <BuildingStatusComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.evacuee.safety.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Evacuee_Safety ? (
                    <EvacueeSafetyComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.floor.evacstatus.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Floor_Evacuation_Status ? (
                    <FloorEvacuationStatusComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.message.evacuee.link + "/:evacueeId"}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_My_Messages ? (
                    <EvacueeMessageComponent
                      {...routeProps}
                      showBurgerMenu={this.showBurgerMenu}
                    />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.message.evacueegroup.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_My_Messages ? (
                    <EvacueeGroupMessageComponent
                      {...routeProps}
                      showBurgerMenu={this.showBurgerMenu}
                    />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.message.warden.link + "/:wardenId"}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Warden_Messaging ? (
                    <WardenMessageComponent
                      {...routeProps}
                      showBurgerMenu={this.showBurgerMenu}
                    />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.message.group.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Warden_Messaging ? (
                    <GroupMessageComponent
                      {...routeProps}
                      showBurgerMenu={this.showBurgerMenu}
                    />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.message.all.link}
                render={(routeProps) =>
                  ls.get("module_access") !== null &&
                  ls.get("module_access").Module_Warden_Messaging ? (
                    <AllMessageComponent {...routeProps} />
                  ) : (
                    <Redirect to={route.reportinlist.link} />
                  )
                }
              />

              <Route
                exact
                path={route.me.role.link}
                render={(routeProps) => (
                  <MyRoleComponent
                    {...routeProps}
                    updateRole={this.updateRole}
                  />
                )}
              />
              {/* 
          This Route MUST be at the END
          Wildcard route catches all other
          routes that are not declared and
          displays a 404 page.
        */}
              <Route path="*" component={NotFound} />
            </Switch>
          </Shell>
        </Route>
      </Switch>
    );
  }
}

export default withRouter(Routes);
