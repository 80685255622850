import React from "react";
import Modal from "..";
import red_exclaimation_icon from "../../../../images/red-exclaimation.svg";
import yellow_exclaimation_icon from "../../../../images/yellow-exclaimation.svg";
import outlined_check_icon from "../../../../images/outlined-check.svg";
import "./styles.scss";

export const AlertModal = (props) => {
  const {
    isOpen,
    message,
    renderMessage,
    variant,
    onClose,
    buttonLabel,
    buttonDisable,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      title=""
      style={{ width: "377px", height: "304px" }}
    >
      <div className="alert-modal">
        <div className="alert-modal__img-container">
          {variant === "error" && (
            <img src={red_exclaimation_icon} alt="red-exclaimation" />
          )}
          {variant === "warn" && (
            <img src={yellow_exclaimation_icon} alt="yellow-exclaimation" />
          )}
          {variant === "success" && (
            <img src={outlined_check_icon} alt="outlined-check" />
          )}
        </div>

        <p className="alert-modal__message">
          {renderMessage ? renderMessage() : message}
        </p>

        <button
          className={`btn ${variant === "error" && "red--bg"} ${variant ===
            "warn" && "yellow--bg"} ${variant === "success" && "green--bg"}`}
          style={{ height: "60px" }}
          onClick={onClose}
          disabled={buttonDisable}
        >
          {buttonLabel ? buttonLabel : "OK"}
        </button>
      </div>
    </Modal>
  );
};
