import React, { Component } from "react";
import "./styles.scss";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      isOpen,
      onClose,
      title,
      hideCloseButton,
      children,
      style,
    } = this.props;

    if (!isOpen) return null;

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div
          className="modal-container"
          style={style}
          onClick={(e) => e.stopPropagation()}
        >
          {!hideCloseButton && (
            <button className="modal-close" onClick={onClose}>
              &times;
            </button>
          )}

          {title && <h2 className="modal-title">{title}</h2>}

          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
