import React, { Component } from "react";
import AU_flag from "../../../../images/AU-flag.svg";
import NZ_flag from "../../../../images/NZ-flag.svg";
import USA_flag from "../../../../images/USA-flag.svg";
import UK_flag from "../../../../images/UK-flag.svg";
import "./styles.scss";

const countryCodeOptions = [
  {
    label: "AU (+61)",
    value: "61",
    flag: AU_flag,
    hint:
      "Australian mobile numbers must be 10 digits starting with 04 (e.g., 0412345678).",
  },
  {
    label: "NZ (+64)",
    value: "64",
    flag: NZ_flag,
    hint:
      "New Zealand mobile numbers must be 10 digits starting with 02 (e.g., 021234567).",
  },
  {
    label: "US (+1)",
    value: "1",
    flag: USA_flag,
    hint:
      "US mobile numbers must be 10 digits starting with the 3-digit area code from (e.g., 212 555 1234).",
  },
  {
    label: "UK (+44)",
    value: "44",
    flag: UK_flag,
    hint:
      "UK mobile numbers must be 11 digits starting with 07 followed by (e.g., 0712 3456789).",
  },
];

class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedCountry: countryCodeOptions[0],
      isError: false,
    };
  }

  componentDidMount() {
    this.getSelectedCountryCode();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { setError } = this.props;
    const { selectedCountry } = this.state;
    const { value } = this.props;

    if (selectedCountry && prevProps && prevProps.value !== value) {
      const AU_Regex = /^04\d{8}$/;
      const NZ_Regex = /^02\d{8}$/;
      const USA_Regex = /^\d{10}$/;
      const UK_Regex = /^07\d{9}$/;

      if (selectedCountry.value === "61") {
        if (!AU_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "64") {
        if (!NZ_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "1") {
        if (!USA_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "44") {
        if (!UK_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      }
    }

    if (prevState.selectedCountry.value !== selectedCountry.value) {
      this.getSelectedCountryCode();
    }
  }

  handleSelectCountry = (option) => {
    this.setState({ selectedCountry: option, isOpen: false });
  };

  getSelectedCountryCode = () => {
    const { onChangeCountryCode } = this.props;
    const { selectedCountry } = this.state;

    if (selectedCountry && selectedCountry.value) {
      onChangeCountryCode(selectedCountry.value);
    }
  };

  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      id,
      label,
      type,
      placeholder = "",
      value,
      onChange,
      className = "",
      fullWidth,
    } = this.props;
    const { isOpen, selectedCountry, isError } = this.state;

    return (
      <div className={`phone-number-input ${fullWidth && "w-100"}`}>
        <div className="phone-number-input__container">
          <div
            className={`input-field outlined`}
            style={{
              width: "45%",
            }}
          >
            <div className="phone-number-input__select w-100 h-100">
              {/* Selected Option */}
              <div
                className="phone-number-input__selected-option"
                onClick={this.toggleDropdown}
                style={{
                  color: !selectedCountry && "#9e9e9e",
                }}
              >
                {selectedCountry && (
                  <img src={selectedCountry.flag} alt="selected-flag" />
                )}

                {selectedCountry && selectedCountry.label
                  ? selectedCountry.label
                  : "Select an option"}
              </div>

              {/* Dropdown Options */}
              {isOpen && (
                <ul className="phone-number-input__dropdown_options">
                  {countryCodeOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => this.handleSelectCountry(option)}
                      className="phone-number-input__dropdown_option"
                    >
                      <img src={option.flag} alt="country" />
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}

              <button
                className="phone-number-input__icon-btn"
                onClick={this.toggleDropdown}
              >
                {isOpen ? (
                  <i className="material-icons">keyboard_arrow_up</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_down</i>
                )}
              </button>
            </div>
          </div>

          <div
            className={`input-field outlined`}
            style={{
              width: "55%",
            }}
          >
            <input
              id={id || "outlined-input"}
              className={`outlined-input-field ${
                isError ? "outlined-input-field--error" : ""
              } ${className}`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              type={type}
            />

            {label && <label for="outlined-input">{label}</label>}
          </div>
        </div>

        {selectedCountry && (
          <div
            className="phone-number-input__hint"
            style={{ color: isError ? "#EB2A31" : "#000000" }}
          >
            {selectedCountry.hint}
          </div>
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
