import ls from "local-storage";

export const setEventToLocalstorage = (status) => {
  let event = {};

  if (status === "start") {
    event = {
      status: true,
      goToReportInListPage: false,
    };
  } else if (status === "end") {
    event = {
      status: false,
      goToReportInListPage: true,
    };
  } else {
    // Default event (if there is no status parameter)
    event = {
      status: false,
      goToReportInListPage: false,
    };
  }

  ls.set("event", event);
};
