import React, { Component } from "react";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import ReportInCard from "./ReportInCard";
import Layout from "../UI/Layout";
import BackIcon from "../../images/back.svg";
import {
  api_warden_reportin_list_url,
  api_warden_single_reportin_list_url,
  api_warden_report_not_available_url,
  api_rejoin_url,
  api_reportin_index_url,
  api_v2_warden_reportin_url,
} from "../../config";
import { AlertModal } from "../UI/Modal/AlertModal";
import "./styles.scss";

class ReportInList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportInListItems: [],
      alertModal: {
        isOpen: false,
        message: "",
        variant: "", // success | error | warn
      },
    };
  }

  fetchReportInList = async () => {
    let url;
    const wardenSignInUID = ls.get("auth").WardenSignInUID;

    if (ls.get("isReportInToken")) {
      // If user login with reportInToken
      url = `${api_warden_single_reportin_list_url}/${wardenSignInUID}`;
    } else {
      const signInMethod = ls.get("signInMethod");
      url = `${api_warden_reportin_list_url}?signInMethod=${signInMethod}`;
    }

    axios
      .get(url)
      .then((res) => this.setState({ reportInListItems: res.data }));
  };

  fetchReportInIndex = async () => {
    return await axios.post(api_reportin_index_url, null);
  };

  updateWardenStatusToOnDuty = async (Warden_Sign_In_UID) => {
    await axios.post(api_v2_warden_reportin_url, {
      Warden_Sign_In_UID,
    });
  };

  componentDidMount() {
    this.fetchReportInList();

    ls.remove("module_access");
    ls.remove("reportInDetails");
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });
  };

  redirectToReportInPage = async (Person_UID, Warden_Sign_In_UID) => {
    await this.handleRejoinWithPersonId(Person_UID);
    await this.updateWardenStatusToOnDuty(Warden_Sign_In_UID);

    this.props.history.push("/me/role");
  };

  handleReportInNotAvailable = async (Warden_Sign_In_UID) => {
    await axios.post(api_warden_report_not_available_url, {
      Warden_Sign_In_UID,
    });
    await this.fetchReportInList();
    this.handleOpenAlertModal(
      "You have been reported as not available. You may sign in later if you are available to assist.",
      "warn"
    );
  };

  handleRejoinOauthToken = async (oauth_token) => {
    await axios.post(api_rejoin_url, { oauth_token }).then((res) => {
      ls.set("auth", {
        ...res.data,
        access_token: `Bearer ${res.data.access_token}`,
      });
    });
    this.props.history.push("/me/role");
  };

  handleRejoinWithPersonId = async (Person_UID) => {
    await axios.post(api_rejoin_url, { person_uid: Person_UID }).then((res) => {
      ls.set("auth", {
        ...res.data,
        access_token: `Bearer ${res.data.access_token}`,
      });
    });
  };

  redirectToWardenAssignmentPage = async (
    Warden_Assignment_UID,
    reportInDetails
  ) => {
    ls.set("reportInDetails", {
      clientSite: reportInDetails.clientSite,
      role: reportInDetails.role,
      area: reportInDetails.area,
      dateFrom: reportInDetails.dateFrom,
      dateTo: reportInDetails.dateTo,
    });

    this.props.history.push(`/warden-assignment/${Warden_Assignment_UID}`);
  };

  redirectToWithdrawPage = async (Warden_Assignment_UID, reportInDetails) => {
    ls.set("reportInDetails", {
      clientSite: reportInDetails.clientSite,
      role: reportInDetails.role,
      area: reportInDetails.area,
      dateFrom: reportInDetails.dateFrom,
      dateTo: reportInDetails.dateTo,
    });

    this.props.history.push(`/withdraw/${Warden_Assignment_UID}`);
  };

  redirectToEventManagementPage = async () => {
    const res = await this.fetchReportInIndex();

    this.props.updateRole(res.data.data.role);

    this.props.history.push("/event/management");
  };

  render() {
    const { reportInListItems, alertModal } = this.state;
    return (
      <Layout>
        <div className="report-in__top-action">
          <button
            className="report-in__top-back-btn"
            onClick={this.props.handleLogout}
          >
            <img src={BackIcon} alt="Back" />
          </button>
        </div>

        <div className="report-in__list-page">
          {_.get(reportInListItems, "length", 0) > 0
            ? reportInListItems.map((reportIn, index) => {
                return (
                  <ReportInCard
                    key={index}
                    {...reportIn}
                    redirectToReportInPage={this.redirectToReportInPage}
                    handleReportInNotAvailable={this.handleReportInNotAvailable}
                    handleRejoinOauthToken={this.handleRejoinOauthToken}
                    redirectToWardenAssignmentPage={
                      this.redirectToWardenAssignmentPage
                    }
                    redirectToWithdrawPage={this.redirectToWithdrawPage}
                    redirectToEventManagementPage={
                      this.redirectToEventManagementPage
                    }
                  />
                );
              })
            : "No report in found"}

          <AlertModal
            isOpen={alertModal.isOpen}
            message={alertModal.message}
            variant={alertModal.variant}
            onClose={this.handleCloseAlertModal}
          />
        </div>
      </Layout>
    );
  }
}

export default ReportInList;
