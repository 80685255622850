import React, { Fragment, Component } from "react";
import _ from "lodash";
import "./scss/event.scss";
import {
  api_event_create_data_url,
  api_event_store_url,
  api_event_end_url,
} from "../../../config";
import { route } from "../../Routes";
import axios from "axios";
import { withRouter, Redirect } from "react-router";
import BuildingList from "./BuildingList";
import CreateEvent from "./CreateEvent";
import CreateEventNotice from "../../Utilities/CreateEventNotice/v2";
import Loader from "../../Utilities/Loader";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";
import { AlertModal } from "../../UI/Modal/AlertModal";
import CreateEventModal from "./CreateEventModal";
import EndEventModal from "./EndEventModal";
import { reloadPage, setEventToLocalstorage } from "../../../utils";

class Event extends Component {
  state = {
    sms_allowed: false,
    redirectReportIn: false,
    loading: true,
    modal: {
      createEvent: false,
      endEvent: false,
    },
    sites: [],
    sitesBuildings: [],
    eventTypes: [],
    userInput: {
      buildings: [],
      eventType: "",
      message: "",
      broadcastMessage: "yes",
      alertToWarden: "yes",
    },
    activeEvent: false,
    eventFormAlert: {
      message: "",
      variant: "",
    },
    eventModalAlert: {
      message: "",
      variant: "",
      fontWeight: "unset",
    },
    isAllActiveEventBuildingAreSelected: false,
    isSomeActiveEventBuildingAreSelected: false,
    isSomeNonActiveEventBuildingAreSelected: false,
    popup: {
      show: false,
      message: "",
      redirect: false,
    },
    alertModal: {
      isOpen: false,
      message: "",
      variant: "", // success | error | warn
    },
    toast: {
      show: false,
      message: "",
    },
  };

  componentDidMount() {
    this.getSites();
    if (!this.state.activeEvent) {
      this.props.showBackBtn(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userInput.buildings !== this.state.userInput.buildings) {
      const sitesBuildings = _.get(this.state, "sitesBuildings", []);
      const userInputBuildings = _.get(this.state, "userInput.buildings", []);
      const isEventActive = _.get(this.state, "activeEvent.active") === true;

      const sitesBuildingsWithActiveEvent = sitesBuildings.filter(
        (filteredSitesBuilding) => filteredSitesBuilding.hasEvent === true
      );
      const sitesBuildingsWithNonActiveEvent = sitesBuildings.filter(
        (filteredSitesBuilding) => filteredSitesBuilding.hasEvent === false
      );

      // All buildings with active event are selected
      const isAllActiveEventBuildingAreSelected = this.allIdsExist(
        sitesBuildingsWithActiveEvent,
        userInputBuildings
      );

      // Some buildings with active event are selected
      const isSomeActiveEventBuildingAreSelected = isAllActiveEventBuildingAreSelected
        ? false
        : this.someIdExists(sitesBuildingsWithActiveEvent, userInputBuildings);

      // Some buildings with non active event are selected
      const isSomeNonActiveEventBuildingAreSelected = this.someIdExists(
        sitesBuildingsWithNonActiveEvent,
        userInputBuildings
      );

      this.setState({
        isAllActiveEventBuildingAreSelected,
        isSomeActiveEventBuildingAreSelected,
        isSomeNonActiveEventBuildingAreSelected,
      });

      if (
        !isEventActive ||
        (isSomeNonActiveEventBuildingAreSelected && !isEventActive)
      ) {
        this.setState({
          eventFormAlert: {
            ...prevState.eventFormAlert,
            message:
              "Select building(s) and enter details for the new emergency event.",
            variant: "warn",
          },
          eventModalAlert: {
            ...prevState.eventModalAlert,
            message: "Confirm creation of new emergency event.",
            variant: "warn",
            fontWeight: "unset",
          },
        });
      } else if (isEventActive) {
        this.setState({
          eventFormAlert: {
            ...prevState.eventFormAlert,
            message:
              "Conclude the current event or add/remove selected building(s) from the current event.",
            variant: "warn",
          },
        });

        if (
          isSomeNonActiveEventBuildingAreSelected &&
          !isAllActiveEventBuildingAreSelected
        ) {
          this.setState({
            eventFormAlert: {
              ...prevState.eventFormAlert,
              message: "Add selected building(s) to the current event.",
              variant: "warn",
            },
            eventModalAlert: {
              ...prevState.eventModalAlert,
              message:
                "Confirm addition of selected building(s) to the current emergency event.",
              variant: "warn",
              fontWeight: "unset",
            },
          });
        }

        if (isSomeActiveEventBuildingAreSelected) {
          this.setState({
            eventFormAlert: {
              ...prevState.eventFormAlert,
              message: "Conclude the current event at selected building(s).",
              variant: "error",
            },
            eventModalAlert: {
              ...prevState.eventModalAlert,
              message:
                "Confirm conclusion of the emergency event at selected building(s).",
              variant: "error",
              fontWeight: "unset",
            },
          });
        }

        if (isAllActiveEventBuildingAreSelected) {
          this.setState({
            eventFormAlert: {
              ...prevState.eventFormAlert,
              message:
                "Conclude the current event or add/remove selected building(s) from the current event.",
              variant: "error",
            },
            eventModalAlert: {
              ...prevState.eventModalAlert,
              message: "Confirm conclusion of the emergency event at selected building(s).".toUpperCase(),
              variant: "error",
              fontWeight: "bold",
            },
          });
        }
      }
    }
  }

  getIsAllActiveEventBuildingAreSelected = () => {
    const sitesBuildings = _.get(this.state, "sitesBuildings", []);
    const userInputBuildings = _.get(this.state, "userInput.buildings", []);

    const sitesBuildingsWithActiveEvent = sitesBuildings.filter(
      (filteredSitesBuilding) => filteredSitesBuilding.hasEvent === true
    );

    // All buildings with active event are selected
    const isAllActiveEventBuildingAreSelected = this.allIdsExist(
      sitesBuildingsWithActiveEvent,
      userInputBuildings
    );

    return isAllActiveEventBuildingAreSelected;
  };

  loading = (loading) => {
    this.setState({
      ...this.state,
      loading,
    });
  };

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });

    reloadPage();
  };

  /**
   * This methods sets the initial state
   * of the component
   */
  getSites = () => {
    this.loading(true);
    axios
      .get(api_event_create_data_url)
      .then((resp) => {
        const createData = resp.data;
        this.loading(false);
        this.setState({
          ...this.state,
          sites: createData.data.sites,
          sitesBuildings: _.get(createData, "data.sites[0].buildings", []).map(
            (sitesBuilding) => {
              return {
                ...sitesBuilding,
                hasEvent: Boolean(_.get(sitesBuilding, "event")),
              };
            }
          ),
          eventTypes: createData.data.eventTypes,
          activeEvent: createData.data.activeEvent,
          sms_allowed: createData.data.sms_allowed,
          eventFormAlert: {
            message:
              _.get(createData.data, "activeEvent.active", false) === true
                ? "Conclude the current event or add/remove selected building(s) from the current event."
                : "Select building(s) and enter details for the new emergency event.",
            variant: "warn",
          },
        });
      })
      .catch((err) => {
        this.loading(false);
        this.props.history.push(route.reportin.link);
      });
  };

  /**
   * This method makes a call to the api
   * to create a event
   */
  createEvent = () => {
    this.closeCreateModal(true);

    const sitesBuildings = _.get(this.state, "sitesBuildings", []);
    const userInputBuildings = _.get(this.state, "userInput.buildings", []);
    const sitesBuildingsWithNonActiveEvent = sitesBuildings.filter(
      (filteredSitesBuilding) => filteredSitesBuilding.hasEvent === false
    );
    const isCreateEventOrAddBuildingToEvent = this.someIdExists(
      sitesBuildingsWithNonActiveEvent,
      userInputBuildings
    );
    const isEventActive =
      _.get(this.state, "activeEvent.active", false) === true;

    let newValue = this.state.userInput;

    if (isEventActive) {
      newValue = {
        ...newValue,
        alertToWarden: "no",
      };
    }

    axios
      .post(api_event_store_url, newValue)
      .then((resp) => {
        if (resp.data.status === "event_created") {
          this.setState({
            ...this.state,
            loading: false,
            // popup: {
            //   show: true,
            //   message: resp.data.message,
            //   redirect: true,
            // },
          });

          if (
            isCreateEventOrAddBuildingToEvent &&
            _.get(this.state, "activeEvent.active", false) === true
          ) {
            this.handleOpenAlertModal(
              "The building has successfully been added to the current event.",
              "success"
            );
          } else {
            this.handleOpenAlertModal(
              "Event has been successfully created.",
              "success"
            );
          }

          setEventToLocalstorage("start");
        } else if (resp.data.status === "event_running") {
          this.setState({
            ...this.state,
            loading: false,
            // popup: {
            //   show: true,
            //   message: resp.data.message,
            //   redirect: true,
            // },
          });

          this.handleOpenAlertModal(resp.data.message, "warn");
        }
      })
      .catch((err) => {
        this.loading(false);
      });
  };

  /**
   * Shows the modal  by settings
   * modal->show to true
   */
  showCreateModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        createEvent: true,
      },
    });
  };

  /**
   * This method handles confirmation Popup
   * close by setting the modal->show to false
   */
  closeCreateModal = (load) => {
    if (load === undefined) {
      load = false;
    }
    this.setState({
      ...this.state,
      loading: load,
      modal: {
        ...this.state.modal,
        createEvent: false,
      },
    });
  };

  /**
   * This method changes the state of userInput
   * It sets the eventType to the param
   *
   * @param eventType
   * @type Int
   */
  changeEventType = (eventType, message) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        eventType,
        message,
      },
    });
  };

  /**
   * This method changes the state of userInput.
   * It sets the message to the param
   *
   * @param message
   * @type string
   */
  changeDefaultMessage = (message) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        message,
      },
    });
  };

  /**
   * This Method iterates through all the selected buildings and
   * returns a chip of it along with its name
   */
  renderSelectedBuildings = () => {
    return this.state.userInput.buildings.map((bldg) => {
      return (
        <div className="chip" key={bldg.id}>
          {bldg.name}
        </div>
      );
    });
  };

  clearUserInputBuildings = () => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        buildings: [],
      },
    });
  };

  updateUserInputBuildings = (buildingsArray) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        buildings: [...buildingsArray],
      },
    });
  };

  /**
   * Shows the modal  by settings
   * modal->show to true
   */
  showEndModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        endEvent: true,
      },
    });
  };

  /**
   * This method handles confirmation Popup
   * close by setting the modal->show to false
   */
  closeEndModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        endEvent: false,
      },
    });
  };

  /**
   * Checks if everything is selected / valid
   * before it returns true
   */
  enableInitialButton = () => {
    if (
      this.state.userInput.audience !== "" &&
      this.state.userInput.buildings.length !== 0 &&
      this.state.userInput.eventType !== "" &&
      this.state.userInput.message !== "" &&
      this.enableEndEventBtn() === false
    ) {
      return true;
    }
    return false;
  };

  enableEndEventBtn = () => {
    let selectedBuildingsLength = this.state.userInput.buildings.length;
    let countCheck = this.state.userInput.buildings.filter((bldg) => {
      return bldg.hasEvent;
    }).length;

    if (selectedBuildingsLength === countCheck && countCheck !== 0) {
      return true;
    } else {
      return false;
    }
  };

  renderAlertMessage = (status) => {
    if (status === "end event") {
      return (
        <Fragment>
          The Event has been concluded.
          <br />
          All Wardens will be signed out.
        </Fragment>
      );
    }
  };

  endEvents = () => {
    this.closeEndModal();

    const isAllActiveEventBuildingAreSelected = this.getIsAllActiveEventBuildingAreSelected();

    axios
      .post(api_event_end_url, _.omit(this.state.userInput, ["alertToWarden"]))
      .then((resp) => {
        if (
          resp.data.status !== undefined &&
          resp.data.status === "event_ended"
        ) {
          this.clearUserInputBuildings();

          this.getSites();

          if (isAllActiveEventBuildingAreSelected) {
            this.handleOpenAlertModal(
              this.renderAlertMessage("end event"),
              "success"
            );

            setEventToLocalstorage("end");
          } else {
            this.handleOpenAlertModal(
              "The building(s) have been removed from the event. Affected Wardens be signed out.",
              "success"
            );
          }
        } else {
          console.log(resp.data.message);
        }
      })
      .catch((err) => {});
  };

  renderPopup = () => {
    if (!this.state.loading && this.state.popup.show) {
      return (
        <div className="eventPopup__overlay">
          <div className="eventPopup__content">
            <div className="eventPopup__content--message">
              {this.state.popup.message}
            </div>
            <div className="eventPopup__action">
              <button onClick={this.handlePopupActionClick}>Ok</button>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePopupActionClick = () => {
    if (this.state.popup.redirect) {
      this.setState({
        ...this.state,
        redirectReportIn: true,
      });
    } else {
      this.setState({
        ...this.state,
        popup: {
          ...this.state.popup,
          show: false,
        },
      });
    }
  };

  closeToast = () => {
    if (this.state.toast.show) {
      this.setState({
        ...this.state,
        toast: {
          show: false,
          message: "",
        },
      });
    }
  };

  someIdExists = (a, b) =>
    a.some((itemA) => b.some((itemB) => itemB.id === itemA.id));

  allIdsExist = (a, b) =>
    a.every((itemA) => b.some((itemB) => itemB.id === itemA.id));

  handleChangeBroadcastMessage = (newValue) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        broadcastMessage: newValue,
      },
    });
  };

  handleChangeAlertToWarden = (newValue) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        alertToWarden: newValue,
      },
    });
  };

  render() {
    if (this.state.redirectReportIn) {
      return <Redirect to={route.reportin.link} />;
    }

    const eventName =
      this.state.eventTypes !== null
        ? this.state.eventTypes
            .filter((eventType) => {
              return eventType.id === this.state.userInput.eventType;
            })
            .map((eventType) => eventType.type)
        : "";

    const endEventName =
      this.enableEndEventBtn() === true
        ? this.state.userInput.buildings[0].event.type
        : "";

    return (
      <React.Fragment>
        {this.renderPopup()}

        <Loader loading={this.state.loading} />

        <Toast
          key="event Toast"
          toastType={TOAST_TYPE.WARNING}
          show={this.state.toast.show}
          close={this.closeToast}
        >
          {this.state.toast.message}
        </Toast>

        <div className="container">
          <CreateEventNotice
            message={this.state.eventFormAlert.message}
            variant={this.state.eventFormAlert.variant}
          />

          <div className="col s12 event__createForm">
            <BuildingList
              sites={this.state.sites}
              updateSelectedBuildings={this.updateUserInputBuildings}
              clearSelectedBuildings={this.clearUserInputBuildings}
              loading={this.state.loading}
              // endEventBtnEnable={this.enableEndEventBtn()}
              // showModal={this.showEndModal}
            />

            <CreateEvent
              sites={this.state.sites}
              eventTypes={this.state.eventTypes}
              initiateEventBtnEnable={this.enableInitialButton()}
              changeType={this.changeEventType}
              changeMessage={this.changeDefaultMessage}
              showModal={this.showCreateModal}
              showEndModal={this.showEndModal}
              loading={this.state.loading}
              input={this.state.userInput}
              activeEvent={this.state.activeEvent}
              sms_allowed={this.state.sms_allowed}
              broadcastMessage={this.state.userInput.broadcastMessage}
              alertToWarden={this.state.userInput.alertToWarden}
              isDisableBroadcastMessageButton
              handleChangeBroadcastMessage={this.handleChangeBroadcastMessage}
              handleChangeAlertToWarden={this.handleChangeAlertToWarden}
              userInputBuildings={_.get(this.state, "userInput.buildings", [])}
              isAllActiveEventBuildingAreSelected={
                this.state.isAllActiveEventBuildingAreSelected
              }
              isSomeActiveEventBuildingAreSelected={
                this.state.isSomeActiveEventBuildingAreSelected
              }
              isSomeNonActiveEventBuildingAreSelected={
                this.state.isSomeNonActiveEventBuildingAreSelected
              }
              endEvents={this.endEvents}
            />
          </div>

          <CreateEventModal
            isOpen={this.state.modal.createEvent}
            eventName={eventName}
            activeEvent={this.state.activeEvent}
            buildingName={_.get(this.state, "sites[0].name", "")}
            userInput={this.state.userInput}
            eventModalAlert={this.state.eventModalAlert}
            renderSelectedBuildings={this.renderSelectedBuildings}
            handleClose={this.closeCreateModal}
            handleSubmit={this.createEvent}
          />

          <EndEventModal
            isOpen={this.state.modal.endEvent}
            eventName={endEventName}
            buildingName={_.get(this.state, "sites[0].name", "")}
            userInput={this.state.userInput}
            eventModalAlert={this.state.eventModalAlert}
            renderSelectedBuildings={this.renderSelectedBuildings}
            handleClose={this.closeEndModal}
            handleSubmit={this.endEvents}
          />

          <AlertModal
            isOpen={this.state.alertModal.isOpen}
            message={this.state.alertModal.message}
            variant={this.state.alertModal.variant}
            onClose={this.handleCloseAlertModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Event);
