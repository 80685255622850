import React, { Component } from "react";

import WardenDot, { DOT_TYPE } from "./wardendot";

import { route } from "../../../Routes";
import { Link } from "react-router-dom";

class WardenListPopup extends Component {
  renderWardenMessageLink = (warden) => {
    if (this.props.warden_id === warden.id) {
      return null;
    }

    return (
      <Link
        to={
          route.message.warden.link +
          "/" +
          warden.id +
          "?from=" +
          route.floor.warden.status.link
        }
      >
        <i className="material-icons blue-text">message</i>
      </Link>
    );
  };

  renderSpecificWardens = (type) => {
    return this.props.wardens.map((warden) => {
      if (warden.status.toString().toLowerCase() === type) {
        return (
          <div
            className="row WardenListPopup__row"
            key={"wlp" + warden.id + " " + warden.name}
          >
            <div className="col s2 WardenListPopup__dot">
              <WardenDot status={warden.status} />
            </div>
            <div className="col s7  WardenListPopup__name">{warden.name}</div>
            <div className="col s3 WardenListPopup__actions">
              <a href={`tel:${warden.phone}`}>
                <i className="material-icons green-text">phone</i>
              </a>

              {this.renderWardenMessageLink(warden)}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  renderWardenList = () => {
    return this.props.wardens.length ? (
      <React.Fragment>
        {this.renderSpecificWardens(DOT_TYPE.ODT)}
        {this.renderSpecificWardens(DOT_TYPE.UKN)}
        {this.renderSpecificWardens(DOT_TYPE.NA)}
        {this.renderSpecificWardens(DOT_TYPE.OFDT)}
      </React.Fragment>
    ) : (
      <div className="row WardenListPopup__row">
        <div className="col s2 WardenListPopup__dot" />
        <div className="col s10  WardenListPopup__error">No Floor Wardens</div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container WardenListPopup">
          <div className="WardenListPopup__inner">
            {this.renderWardenList()}
          </div>
        </div>
        <div
          className="WardenListPopup__overlay"
          onClick={this.props.closePopup}
        />
      </React.Fragment>
    );
  }
}

export default WardenListPopup;
