import React, { Component } from "react";
import { formatDate } from "../../utils";
import WardenRoleIcon from "../../images/warden-role-626262.svg";
import DateFromToIcon from "../../images/dateFromTo.svg";
import AssignmentLocationIcon from "../../images/assignment-location.svg";
import WhiteAddCircleIcon from "../../images/white-add-circle.svg";
import WhiteCheckCircleIcon from "../../images/white-check-circle.svg";
import QuestionCircleIcon from "../../images/question-circle.svg";
import BlackMinusCircleIcon from "../../images/black-minus-circle.svg";
import XCircleIcon from "../../images/x-circle.svg";

class ReportInCard extends Component {
  getButtonBgColor = (color) => {
    if (color === "Green") {
      return "#00B050";
    } else if (color === "Yellow") {
      return "#ECC60D";
    } else if (color === "Grey") {
      return "#D8D8DD";
    }
  };

  getButtonTextColor = (color) => {
    if (color === "Green") {
      return "#FFFFFF";
    } else {
      return "#333333";
    }
  };

  getIconButton = (buttonIcon) => {
    if (buttonIcon && buttonIcon.toLowerCase() === "plus") {
      return <img src={WhiteAddCircleIcon} alt="Plus" />;
    } else if (buttonIcon && buttonIcon.toLowerCase() === "minus") {
      return <img src={BlackMinusCircleIcon} alt="minus" />;
    } else if (buttonIcon && buttonIcon.toLowerCase() === "cross") {
      return <img src={XCircleIcon} alt="cross" />;
    } else if (buttonIcon && buttonIcon.toLowerCase() === "tick") {
      return <img src={WhiteCheckCircleIcon} alt="tick" />;
    } else if (buttonIcon && buttonIcon.toLowerCase() === "question") {
      return <img src={QuestionCircleIcon} alt="question" />;
    } else if (buttonIcon && buttonIcon.toLowerCase() === "blank") {
      return null;
    }
  };

  getButtonAction = (Button1_Label, Button2_Label) => {
    const {
      Warden_Assignment_UID,
      Warden_Sign_In_UID,
      oauth_token,
      Person_UID,
      Client_Site,
      Warden_Role,
      Assignment_Location,
      Date_From,
      Date_To,
      redirectToReportInPage,
      handleReportInNotAvailable,
      handleRejoinOauthToken,
      redirectToWardenAssignmentPage,
      redirectToWithdrawPage,
      redirectToEventManagementPage,
    } = this.props;

    const reportInDetails = {
      clientSite: Client_Site,
      role: Warden_Role,
      area: Assignment_Location,
      dateFrom: Date_From,
      dateTo: Date_To,
    };

    const buttonActions = {
      "report in - available": () =>
        redirectToReportInPage(Person_UID, Warden_Sign_In_UID),
      "not available": () => handleReportInNotAvailable(Warden_Sign_In_UID),
      "rejoin event": () => handleRejoinOauthToken(oauth_token),
      "review to accept / decline": () =>
        redirectToWardenAssignmentPage(Warden_Assignment_UID, reportInDetails),
      withdraw: () =>
        redirectToWithdrawPage(Warden_Assignment_UID, reportInDetails),
      "start event": () =>
        redirectToEventManagementPage(Person_UID, Warden_Sign_In_UID),
    };

    const button1Action = buttonActions[Button1_Label.toLowerCase()];
    const button2Action = buttonActions[Button2_Label.toLowerCase()];

    return {
      button1Action,
      button2Action,
    };
  };

  render() {
    const {
      Header,
      Event_Type,
      Client_Site,
      Warden_Role,
      Assignment_Location,
      Date_From,
      Date_To,
      Button1_Label,
      Button1_Colour,
      Button1_Display,
      Button1_Icon,
      Button2_Label,
      Button2_Colour,
      Button2_Display,
      Button2_Icon,
      Footer,
    } = this.props;
    const isEventInProgress = Boolean(Header === "Event In Progress");
    const formatedDateFrom = formatDate(Date_From);
    const formatedDateTo = formatDate(Date_To);
    const { button1Action, button2Action } = this.getButtonAction(
      Button1_Label,
      Button2_Label
    );

    return (
      <div
        className="report-in__card"
        style={{
          border: isEventInProgress ? "3px solid #EB2A31" : "2px solid #626262",
        }}
      >
        {isEventInProgress && (
          <div className="report-in__card-header">{Header}</div>
        )}

        <div className="report-in__card-content">
          {Event_Type && (
            <div className="report-in__card-event-type">{`[ ${Event_Type} ]`}</div>
          )}

          <div className="report-in__card-client-site">{Client_Site}</div>

          <div className="report-in__card-content-details">
            <div className="report-in__card-content-details-img-wrapper">
              <img src={WardenRoleIcon} alt="Warden role" />
            </div>

            {Warden_Role}
          </div>

          <div className="report-in__card-content-details">
            <div className="report-in__card-content-details-img-wrapper">
              <img src={AssignmentLocationIcon} alt="Assignment location" />
            </div>

            {Assignment_Location}
          </div>

          {Date_From && Date_To && (
            <div className="report-in__card-content-details">
              <div className="report-in__card-content-details-img-wrapper">
                <img src={DateFromToIcon} alt="Date from and date to" />
              </div>

              {`${formatedDateFrom} - ${formatedDateTo}`}
            </div>
          )}

          <div className="report-in__card-actions">
            {Button1_Label && (
              <button
                className="report-in__card-btn"
                style={{
                  display: Button1_Display === "Hidden" ? "none" : "flex",
                  backgroundColor: this.getButtonBgColor(Button1_Colour),
                  color: this.getButtonTextColor(Button1_Colour),
                  left: 0,
                }}
                disabled={Button1_Display === "Disabled" ? true : false}
                onClick={button1Action}
              >
                {this.getIconButton(Button1_Icon)}
                {Button1_Label}
              </button>
            )}

            {Button2_Label && (
              <button
                className="report-in__card-btn"
                style={{
                  display: Button2_Display === "Hidden" ? "none" : "flex",
                  backgroundColor: this.getButtonBgColor(Button2_Colour),
                  color: this.getButtonTextColor(Button2_Colour),
                  right: 0,
                }}
                disabled={Button2_Display === "Disabled" ? true : false}
                onClick={button2Action}
              >
                {this.getIconButton(Button2_Icon)}
                {Button2_Label}
              </button>
            )}
          </div>

          {Footer && (
            <div className="report-in__card-helper-text">{Footer}</div>
          )}
        </div>
      </div>
    );
  }
}

export default ReportInCard;
